import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const DialogStandard = ({ open, handleClose, title, message, buttonText = 'GOT IT', buttonAction }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ fontSize: "1.5rem" }}>{title || "Oops!"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message || "Looks like you’ve left some celestial queries unanswered. Complete the current mission log to navigate to your next astral adventure!"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={buttonAction || handleClose} color="primary">
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogStandard;
