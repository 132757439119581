import { Stack } from "@mui/material";
import { Fragment } from "react";
import COIForm from "../Components/COIForm/COIEmployee/COIFrom";
import { Helmet } from "react-helmet";
import getConfig from "../helpers/getConfig";

export default function COIFormRoute() {

  const hostEnv = getConfig()

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{hostEnv.entity} COI Declaration</title>
        <link rel="icon" href={hostEnv.favicon} />
      </Helmet>
      <Stack className={'app-container'} id='base-container' sx={{ height: "100vh", position: 'relative' }}>
        <COIForm />
      </Stack>
    </Fragment>
  )
}