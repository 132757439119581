import { MobileStepper, Step, StepLabel, Stepper } from "@mui/material";
import { Check, X } from "lucide-react"; // Import cross icon
import { Fragment, useEffect, useState } from "react";
import getConfig from "../helpers/getConfig";


//  if (hostEnv.env === 'CARS24' || hostEnv.env.toLowerCase() === 'cars24' || hostEnv.env==='local') {
const NO_SECTION_IDS = {
  "104": 2,
  "105": 3,
  "106": 4,
  "107": 5,
  "108": 6,
  "75": 2,
  "76": 3,
  "77": 4,
  "78": 5,
  "79": 6,
  "125": 2,
  "126": 3,
  "127": 4,
  "128": 5,
  "129": 6,
  "84": 2,
  "85": 3,
  "86": 4,
  "87": 5,
  "88": 6
}
//   }
//   else {
//     NO_SECTION_IDS = {
//       "125": 2,
//       "126": 3,
//       "127": 4,
//       "128": 5,
//       "129": 6,
//       "84": 2,
//       "85": 3,
//       "86": 4,
//       "87": 5,
//       "88": 6
//   }
//  };


export default function AppStepper({ sectionedQuestions, currentIndex, values, isAlreadySubmitted = false }) {
  const uniqueSectionTitles = [
    'About', 'My-Info', 'Glow-Up', 'Money-Talks', 'Move-aid', 'Role-lock', 'Shift the Vibe', 'Confirm', "Join The Squad"
  ];

  const location = window.location.href || "https://feedback-survey.qac24svc.dev/mission-elevate";
  const pathname = new URL(location).pathname;
  const hostEnv = getConfig();
  const isMobile = window.innerWidth < 1024;

  const URL_TITLE_MAP = {
    "/": "Conflict of Interest (COI) - Declaration",
    "/it-asset": "IT Assets Insurance",
    "/mission-elevate": "Mission Elevate Show Your Enthusiasm",
  };

  // State to hold the "Nah" selected question IDs
  const [nahSelectedValues, setNahSelectedValues] = useState([]);

  useEffect(() => {
    if (sectionedQuestions) {
      const optionsMap = Object.values(sectionedQuestions).flatMap(sectionArray =>
        sectionArray.flatMap(section =>
          section.options.map(option => ({
            [option.id.toString()]: option.toolTip,
          }))
        )
      );

      const tooltipMap = optionsMap.reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});

      const nahSelections = Object.keys(values).filter(questionId => {
        const optionId = values[questionId];
        return tooltipMap[optionId] === "Nah"; // Check for "Nah" selections
      });

      setNahSelectedValues(nahSelections); // Store the selected "Nah" IDs
    }
  }, [values, sectionedQuestions]);

  const getActiveStep = () => {
    if (currentIndex === 0) {
      return 0;
    }
    return Math.max(1, currentIndex);
  };

  return (
    <Fragment>
      <div
        style={{
          fontSize: "20px",
          paddingTop: "20px",
          fontWeight: "500",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "28px",
          color: "white",
        }}
      >
        <div style={{ fontSize: "32px", fontWeight: "600" }}>
          {pathname === "/mission-elevate" ? (
            <>
              <span style={{ color: "orange" }}>Mission Elevate</span>{" "}
            </>
          ) : (
            URL_TITLE_MAP[pathname]
          )}
        </div>
        {pathname === "/mission-elevate" && (
          <div>
            <img height={40} src={hostEnv.logo} alt="Logo" />
          </div>
        )}
      </div>

      {currentIndex > 0 && !isAlreadySubmitted && (
        <div style={{ fontSize: "20px", fontWeight: "500", textAlign: "left" }}>
          {isMobile ? (
            <MobileStepper
              variant="progress"
              steps={uniqueSectionTitles.length}
              position="static"
              activeStep={getActiveStep()}
              sx={{
                ".MuiLinearProgress-root": {
                  width: "100%",
                },
              }}
            />
          ) : (
            <Stepper
              alternativeLabel
              activeStep={getActiveStep()}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {uniqueSectionTitles.map((sectionTitle, index) => (
                <Step key={index}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <CustomIcon
                        {...props}
                        index={index}
                        currentIndex={currentIndex}
                        nahSelectedValues={nahSelectedValues} // Pass the Nah selections
                      />
                    )}
                    className="stepLabelWhite"
                  >
                    {sectionTitle}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
        </div>
      )}
    </Fragment>
  );
}

function CustomIcon({ active, completed, index, currentIndex, nahSelectedValues }) {
  const isCurrentSection = currentIndex === index;

  const shouldShowCross = Object.entries(NO_SECTION_IDS).some(
    ([questionId, sectionIndex]) =>
      sectionIndex === index && nahSelectedValues.includes(questionId)
  );

  return (
    <div style={{
      // shouldShowCross && !completed ? "#FFFFFF" : completed ? "#0171BC" : "#FFFFFF"
      background: shouldShowCross && (index <= currentIndex) ? 'red' : completed ? "#0171BC" : "#FFFFFF",
      border: shouldShowCross && (index <= currentIndex) ? "none" :
        completed
          ? "none"
          : isCurrentSection
            ? "3px solid #F7931D"
            : "3px solid grey",
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center',
      width: 24,
      height: 24,
      borderRadius: '50%',
      color: "#fff",
      fontWeight: "bold",
      fontSize: "12px"
    }}>
      {shouldShowCross && (index <= currentIndex) ? (
        <X size={18} color="white" /> // Show cross if shouldShowCross is true
      ) : completed ? (
        <Check size={18} color="white" /> // Show check if completed
      ) : active ? (
        ""
      ) : (
        ""
      )}
    </div>
  );
}
