import { useEffect } from "react";
import LoginFormDesktop from "../LoginFormDesktop/LoginFormDesktop";
import LoginFormMobile from "../LoginFormMobile/LoginFormMobile";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import getConfig from "../../../helpers/getConfig";

export default function LoginForm() {
  const location = useLocation();
  const navigate = useNavigate();

  const isMobile = window.innerWidth <= 1024;

  const getAccessToken = (authCode) => {
    const config = getConfig();

    const env = config.env;

    let headers = config.headers;

    // if (env === "production") {
    //   headers = {
    //     'client_id': 'hrms',
    //     'client_secret': 'H5lZJefduGyG5rtATfVCfTiymsanXPNk',
    //     'x-channel-name': 'hrms-app',
    //     'Content-Type': 'application/json',
    //     'Cookie': '__cf_bm=S70CrleTCopjk5AR8T262BevWXGvCuS4svd1_nWHMNE-1723637246-1.0.1.1-WlCv.0eSh572S_tlQR5XWFQ4umhpNHmMDUoUtTcCcLd5..uh9uSHJSdlxSpNRxWsBK803QpCH14xdK5P2YJtBA; __cf_bm=KbYQvIVKW4RRF6nlklQnUJqxeM7kCRxAgmApRAzHb8A-1723788789-1.0.1.1-.phVdXIiInVZeuwtqXPCpBU40jYiuR6Rnt7SygmFuEBuySuAB55C5izEW1KD6U4zn4f.7omjiBrWH976vASMrw'
    //   }
    // } else if (env === "local") {
    //   headers = {
    //     'client_id': 'hrms-local',
    //     'client_secret': 'UmVamAqt59Thg2427cw1o4wGLpyUOkTY',
    //     'x-channel-name': 'hrms-app',
    //     'Content-Type': 'application/json'
    //   }
    // } else {
    //   headers = {
    //     'client_id': 'hrms',
    //     'client_secret': 'vl5d0JrOEdA41zu8S93j9N0omQYNkKZ1',
    //     'x-channel-name': 'hrms-app',
    //     'Content-Type': 'application/json'
    //   }
    // }

    // Perform the POST request
    axios.post(config.loginBaseUrl, {
      authorizationCode: authCode,
      identityProvider: "GOOGLE",
      redirectUri: config?.redirectUri || "https://feedback-survey.qac24svc.dev/login"
    }, { headers })
      .then((response) => {
        const jwt = response?.data?.data?.accessToken;
        localStorage.setItem("accessToken", jwt);
        const navigatedFrom = localStorage.getItem("pathname") || "/";
        navigate(`${navigatedFrom}`);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (code) {
      console.log({
        code
      });
      getAccessToken(code);
      // localStorage.setItem('sessionToken', code);
      // navigate('/')
    }
  }, [location, navigate]);

  console.log("LOGIN_PAGE_1234", window.location.href);

  return isMobile ? <LoginFormMobile /> : <LoginFormDesktop />
}