import React from 'react';
import { Navigate } from 'react-router-dom';
import { isTokenValid } from './helpers/utils/validateToken';

const ProtectedRoute = ({ children }) => {
  const linkedAccessed = window.location.href;
  const pathname = new URL(linkedAccessed).pathname;


  const token = localStorage.getItem('accessToken');

  if (!isTokenValid(token)) {
    // If no token is found, redirect to the login page
    localStorage.setItem("pathname", pathname);
    return <Navigate to="/login" replace />;
  }

  // If token is present, render the children components
  return children;
};

export default ProtectedRoute;