import '../LoginForm/loginForm.css';
import loginLeftImg from '../login-left-img.svg';
import LoginLeftCariot from '../login-left-cariot.svg';
import loginRightImg from '../login-right-img.svg';
import GoogleCTA from '../GoogleCTA/GoogleCTA';
import getConfig from '../../../helpers/getConfig';
import { LOGIN_CONFIG } from '../../../helpers/constants';

const hostEnv = getConfig()


export default function LoginFormDesktop() {
  const pathname = localStorage.getItem("pathname") || "/";

  let config;

  if (pathname === "/mission-elevate") {
    config = LOGIN_CONFIG.missionElevate
  } else if (pathname === "/it-asset") {
    config = LOGIN_CONFIG.itAsset
  } else if (pathname === "/cultural-form") {
    config = LOGIN_CONFIG.culturalForm
  }
  else if (pathname === "/tech-session-form") {
    config = LOGIN_CONFIG.dynamicForm
  }
  else {
    config = LOGIN_CONFIG.coi
  }

  const leftAutonautImage = hostEnv.entity === "CARS24" ? loginLeftImg : LoginLeftCariot;


  return (
    <div className='loginForm'>
      <div className='loginFormContainer'>
        {/* LEFT */}
        <div className='loginFormContainerLeft'>
          <h3 className='loginFormTitle' style={{ color: '#F7931D' }}>{config.title}</h3>
          <h3 className="loginFormTitle">{config?.title2}</h3>
          <h3 className="loginFormTitle">{config?.title3}</h3>
          <h3 className="loginFormTitle">{config?.title4}</h3>
          <p className="loginFormDescription">
            <span style={{ fontWeight: 500, color: '#0557AB', fontSize: '24px' }}>{config?.para1}</span>
          </p>
          <div style={{ marginTop: '32px' }}>
            <img height={300} src={leftAutonautImage} alt="login-left" />
          </div>
        </div>
        {/* RIGHT */}
        <div style={{ borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }} className='loginFormContainerRight'>
          <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div>
              <img height={80} width={150} src={hostEnv.logo} alt="cars24-logo" />
            </div>
            <div style={{ marginTop: '32px' }}>
              <h2 style={{ fontSize: '40px', fontWeight: 800, color: '#f7931e' }}>Login</h2>
            </div>
            <div style={{ marginTop: '32px' }}>
              <p style={{ fontSize: '14px', marginBottom: '8px' }}>Login via Google SSO</p>
              <GoogleCTA />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}