import { Stack } from "@mui/material";
import React, { useState } from "react";
import { Fragment } from "react";
import CulturalForm from "../Components/CulturalForm/CulturalForm";
import { Helmet } from "react-helmet";
import getConfig from "../helpers/getConfig";

export default function CulturalFormRoute() {
  const backgroundImg = 'cultural-form-bg-fallback';
  const hostEnv = getConfig()


  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cultural Form</title>
        <link rel="icon" href={hostEnv.favicon} />
      </Helmet>
      <Stack className={backgroundImg} id='base-container' sx={{ height: "100vh", position: 'relative' }}>
        <CulturalForm />
      </Stack>
    </Fragment >
  )
}