import { AppBar, Button, MobileStepper, Step, StepLabel, Stepper, Toolbar } from "@mui/material";
import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import getConfig from "../helpers/getConfig";


export default function AppStepper({ sectionedQuestions, currentIndex }) {
  const uniqueSectionTitles = [...new Set(Object.keys(sectionedQuestions))]
    .filter(sectionTitle => !sectionTitle.includes('Dec.'));

  const location = window.location.href;
  const pathname = new URL(location).pathname;

  const isMobile = window.innerWidth < 1024

  const hostEnv = getConfig()

  const URL_TITLE_MAP = {
    "/": "Conflict of Interest (COI) - Declaration",
    "/mission-elevate": "Mission Elevate",
    "/it-asset": "IT Assets Insurance"
  };

  return (
    <Fragment>
      <AppBar position="fixed" sx={{ background: 'white' }}>
        <Toolbar>
          <div>
            <img height={40} src={hostEnv.logo} alt="logo" />
          </div>
        </Toolbar>
      </AppBar>
      <div style={{ fontSize: '20px', paddingTop: '36px', fontWeight: '500' }}>
        <div style={{ fontSize: '20px', fontWeight: 500, marginBottom: '32px' }} >
          {URL_TITLE_MAP[pathname]}
        </div>
      </div>
      <div style={{ width: '100%', overflowX: 'auto' }}>
        {isMobile ? (
          <MobileStepper
            variant="progress"
            steps={uniqueSectionTitles.length}
            position="static"
            activeStep={currentIndex}
            sx={{
              ".MuiLinearProgress-root": {
                width: '100%'
              }
            }}
          />
        ) : (
          <Stepper
            alternativeLabel
            activeStep={currentIndex}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {uniqueSectionTitles.map((sectionTitle, index) => (
              <Step key={index}>
                <StepLabel>{sectionTitle}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}

      </div>
    </Fragment>
  );
}