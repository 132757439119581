import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { jwtDecode } from 'jwt-decode';
import { Alert, Card, Textarea } from '@mui/material';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import './DynamicForm.css';
import img1 from '../../images/DynamicForm/deepak.png';
import img2 from '../../images/DynamicForm/dushant.png';
import Snackbar from '@mui/material/Snackbar';
import AlertSnackbar from '../core/AlertSnackbar/AlertSnackbar';


const DynamicFeedbackForm = () => {
  const [formData, setFormData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [currentSessionIndex, setCurrentSessionIndex] = useState(-1);
  const [staticContent, setStaticContent] = useState({});
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;


  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          'https://insight360.qac24svc.dev/api/v2/config/rating/hrms/tech-feedback1'
        );

        const initialQuestions = response.data?.data?.initialQuestions || {};

        const sessionStaticContent = response.data?.data?.initialQuestions[325]?.metadata?.section?.staticContent[0] || {};

        setStaticContent(sessionStaticContent);

        // Sort questions by sequence
        const sortedQuestions = Object.values(initialQuestions).sort((a, b) => a.sequence - b.sequence);

        const questionsBySession = {};
        sortedQuestions.forEach((questionData) => {
          const sessionTitle = questionData.metadata.title;
          if (!questionsBySession[sessionTitle]) {
            questionsBySession[sessionTitle] = [];
          }
          questionsBySession[sessionTitle].push(questionData);
        });
        delete questionsBySession["undefined"]

        setQuestions(questionsBySession);

        const initialFormData = {};
        Object.values(initialQuestions).forEach((question) => {
          initialFormData[question.id] = '';
        });
        setFormData(initialFormData);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching questions:', err);
        setError('Failed to load questions.');
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const validateCurrentSessionQuestions = () => {
    // Check if all questions in the current session are answered
    const currentSessionQuestions = questions[currentSessionTitle] || [];
    for (const question of currentSessionQuestions) {
      if (!formData[question.id]) {
        return false;
      }
    }
    return true;
  };

  const handleChange = (questionId, value) => {
    setFormData((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };
  const userDetails = JSON.parse(localStorage.getItem("user-details"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentSessionIndex === sessionTitles.length - 1) {
      const sessionToken = localStorage.getItem("accessToken");
      let email = "";
      if (sessionToken) {
        const decoded = jwtDecode(sessionToken);
        email = decoded?.email;
      }
      // Construct the payload for submission
      const payload = {
        ssId: 127,
        identifierId: uuidv4(),
        identifierType: "DYNAMIC_FORM",
        feedbackGivenFor: "7976efa2-5d7b-481c-9e38-a1868c132892",
        feedbackGivenForEntityType: `EMPLOYEE`,
        answer: Object.entries(formData).map(([questionId, optionId]) => ({
          questionId: Number(questionId),
          optionId: Number(questionId) === 325 ? 994 : Number(questionId) === 334 ? 1011 : Number(optionId),
          weightage: Number(questionId) === 334 ? -24 : 0,
          comment: typeof optionId === 'string' ? optionId : "",
          metadata: {},
        })),
        feedbackGivenByMetadata: {
          externalId: "sdfdsf-34234-sdfsdf-234234",
        },
        feedbackGivenBy: email,
        feedbackGivenByEntityType: "EMPLOYEE",
      };





      try {
        const response = await axios.post(
          "https://insight360.qac24svc.dev/api/v2/feedback",
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-channel-name": "hrms_web",
              token: "Bearer token",
            },
          }
        );

        if (response.status === 200) {

          setFormSubmitted(true);
        } else {

          alert("Failed to submit feedback. Please try again.");
        }
      } catch (error) {
        setState((prev) => ({ ...prev, open: true }));
        toast.success("An error occurred while submitting the form. Please try again.");
      }
    }
  };

  const handleNext = () => {
    if (validateCurrentSessionQuestions()) {
      setCurrentSessionIndex((prevIndex) => prevIndex + 1);
    } else {
      setState((prev) => ({ ...prev, open: true }));
      toast.error("Kindly complete all the questions in the current section before moving forward");
    }
  };



  const handleBack = () => {
    if (currentSessionIndex >= 0) {
      setCurrentSessionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const sessionTitles = Object.keys(questions);
  const currentSessionTitle = sessionTitles[currentSessionIndex];
  const currentSessionQuestions = questions[currentSessionTitle] || [];


  const sortedCurrentSessionQuestions = currentSessionQuestions.sort((a, b) => a.sequence - b.sequence);

  if (loading) {
    return <p>Loading form...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (

    <div className='dynamic-form--main'>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => {
          setState((prev) => ({ ...prev, open: false }));
        }}
        anchorOrigin={{ vertical, horizontal }}
        key={{ vertical, horizontal }}
      >
        <Alert
          variant="filled" severity="error"
          sx={{ backgroundColor: '#ff3333', color: 'white' }}
        >Kindly complete all the questions in the current section before moving forward</Alert>
      </Snackbar>

      <h1 style={{ marginTop: '20px', color: "#ffffff", justifyContent: "center", textAlign: "center" }} className='tech-heading'>Tech Feedback Form</h1>
      {formSubmitted &&
        <Snackbar
          open={true}
          autoHideDuration={3000}
          onClose={() => {
            setState((prev) => ({ ...prev, open: false }));
          }}
          anchorOrigin={{ vertical, horizontal }}
          key={{ vertical, horizontal }}
        >
          <Alert
            variant="filled" severity="success"
            sx={{ backgroundColor: 'green', color: 'white' }}
          >Thank you for your submission!</Alert>
        </Snackbar>}
      {!formSubmitted && (
        <div className="tech-form">
          <form onSubmit={handleSubmit}>
            {currentSessionIndex === -1 && (
              <div style={{ padding: "20px 0px" }}>

                <Card
                  elevation={3}
                  sx={{
                    padding: 3,
                    borderRadius: 2,
                    backgroundColor: '#f9f9f9',
                    width: '100%',
                    height: "100%",
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: 3,
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2}
                    justifyContent="center"
                    mb={2}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: '#F7931E',
                        fontWeight: 500,
                        fontFamily: 'Inter',
                        fontSize: '24px',
                        textAlign: 'center',
                        textUnderlinePosition: 'from-font',
                        textDecorationSkipInk: 'none',
                      }}
                    >
                      {staticContent.heading}
                    </Typography>
                  </Box>

                  <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#34495e',
                        fontWeight: 500,
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        textAlign: 'left',
                        lineHeight: '1.5',

                      }}
                    >
                      {staticContent.description}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#34495e',
                        fontWeight: 500,
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        textAlign: 'left',
                        lineHeight: '1.5',
                      }}
                    >
                      {staticContent.description2}
                    </Typography>
                  </Box>
                </Card>
              </div>

            )}
            {currentSessionIndex >= 0 && currentSessionTitle && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: "sticky", top: 0, background: 'white', padding: "10px 0", borderBottom: "1px solid black" }}>
                  <Typography
                    variant="h5"
                    className='heading-section'

                  >
                    {currentSessionTitle}
                  </Typography>
                  {currentSessionIndex === 0 && (
                    <Avatar alt="Remy Sharp" src={img1} sx={{ width: 100, height: 100 }} />
                  )}
                  {currentSessionIndex === 1 && (
                    <Avatar alt="Remy Sharp" src={img2} sx={{ width: 100, height: 100 }} />
                  )}
                </div>
                <div className="tech-form2">
                  {sortedCurrentSessionQuestions.map((question, index) => (
                    <div key={question.id} style={{ marginBottom: '20px', marginTop: "20px" }}>
                      <label>{index + 1}. {question.name}</label>
                      <div>
                        {question.type === 'TEXT' ? (
                          <textarea
                            rows={6}
                            placeholder="Please share your thoughts"
                            value={formData[question.id] || ''}
                            onChange={(e) => handleChange(question.id, e.target.value)}
                            style={{ padding: "10px", width: '100%', maxWidth: '600px', resize: 'none', fontFamily: 'Arial, sans-serif' }}
                          />
                        ) : (
                          question.options.map((option) => (
                            <label key={option.id} style={{ display: 'block', marginBottom: '8px' }}>
                              <input
                                type="radio"
                                name={`question-${question.id}`}
                                value={option.id}
                                checked={formData[question.id] === option.id}
                                onChange={() => handleChange(question.id, option.id)}
                                style={{ marginRight: '8px' }}
                              />
                              {option.toolTip}
                            </label>

                          ))
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {
              currentSessionIndex === sessionTitles.length - 1 && (
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '20px', marginBottom: '20px'
                  }}
                >
                  <button type='button' onClick={handleBack}>
                    Back
                  </button>
                  <button type="submit">
                    Submit
                  </button>
                </div>

              )
            }

          </form>
          {
            currentSessionIndex < 0 ? <button
              type='button'
              onClick={handleNext}
              style={{ display: 'flex', justifyContent: 'center', width: 'auto', marginTop: '20px', marginBottom: '20px' }}
            >
              Next
            </button> :
              currentSessionIndex !== sessionTitles.length - 1 ?
                <div style={{ display: 'flex', gap: 4, marginBottom: "20px", justifyContent: "center", alignItems: "center", width: '100%' }}>
                  <button type='button' onClick={handleBack}>
                    Back
                  </button>
                  <button
                    type='button'
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div> : <></>
          }
        </div>
      )
      }
    </div >
  );
};

export default DynamicFeedbackForm;

