import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from '@descope/react-sdk';
import { ThemeProvider } from '@mui/material';
import theme from './theme';

const DESCOPE_PROJECT_ID = "P2f8QcddmH7jgG8Cv68F2KXkpZ7M"; // TODO: This needs to be handled


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider projectId={DESCOPE_PROJECT_ID}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
