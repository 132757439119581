
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginForm from './Components/login/LoginForm/LoginForm';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import MissionElevateFormRoute from './routes/MissionElevateFormRoute';
import ItAssetFormRoute from './routes/ItAssetFormRoute';
import CandidateCOIFormRoute from './routes/CandidateCOIFormRoute';
import COIFormRoute from './routes/COIFormRoute';
import getConfig from './helpers/getConfig';
import { titleMap } from './helpers/constants';
import { Helmet } from 'react-helmet';
import CulturalForm from './Components/CulturalForm/CulturalForm';
import CulturalFormRoute from './routes/CulturalFormRoute';
import DynamicFeedbackForm from './Components/DynamicForm/DynamicForm';


function HealthCheck() {
  return <div className='app-container' style={{ color: 'white' }}>OK</div>;
}

function App() {
  const accessToken = localStorage.getItem("accessToken");
  const hostEnv = getConfig()

  if (!accessToken && !window.location.href.includes('/login')) {
    localStorage.setItem("link", window.location.href);
  }

  const currentPath = window.location.pathname;
  console.log(currentPath, "currpath")

  const defaultTitle = `${hostEnv.entity}`;
  const title = titleMap?.currentPath?.title || defaultTitle;
  const favicon = hostEnv.favicon;

  return (
    <Router>
      <Helmet>
        <title>{hostEnv.entity} {title}</title>
        <link rel="icon" href={favicon} />
      </Helmet>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginForm />
            </PublicRoute>
          }
        />
        <Route
          path='/'
          element={
            <ProtectedRoute>
              <COIFormRoute />
            </ProtectedRoute>
          }
        />
        <Route
          path='/mission-elevate'
          element={
            <ProtectedRoute>
              <MissionElevateFormRoute />
            </ProtectedRoute>
          }
        />
        <Route
          path='/it-asset'
          element={
            <ProtectedRoute>
              <ItAssetFormRoute />
            </ProtectedRoute>
          }
        />
        <Route
          path='/candidate'
          element={
            <ProtectedRoute>
              <CandidateCOIFormRoute />
            </ProtectedRoute>
          }
        />
        <Route
          path='/cultural-form'
          element={
            <ProtectedRoute>
              <CulturalFormRoute />
            </ProtectedRoute>
          }
        />
        <Route
          path='/tech-session-form'
          element={
            <ProtectedRoute>
              <DynamicFeedbackForm />
            </ProtectedRoute>
          }
        />
        <Route path="/public/health" element={<HealthCheck />} />
      </Routes>
    </Router >
  );
}

export default App;
