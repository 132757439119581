import { Descope } from "@descope/react-sdk";
import { Button } from "@mui/material";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import googleSvg from './google.svg';
import getConfig from "../../../helpers/getConfig";

export default function GoogleCTA() {
  const navigate = useNavigate();

  const handleGoogleLoginSuccess = (e) => {
    const details = e && e.detail;
    const sessionJwt = details?.sessionJwt;
    localStorage.setItem('sessionToken', sessionJwt);
    navigate('/');
  }

  const handleGoogleLoginError = (e) => {
    console.error(e);
  }

  const handleClick = () => {
    const config = getConfig();

    const clientId = config?.clientId || "565623820835-c8ifgjo1sfi6klr1mjc41jcdjpgq3cum.apps.googleusercontent.com";
    const redirectUri = encodeURIComponent(config.redirectUri || "https://feedback-survey.qac24svc.dev/login");
    const scope = encodeURIComponent("https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid");
    const responseType = "code";
    const accessType = "offline";
    const prompt = "consent";

    // Construct the OAuth link
    const oauthLink = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirectUri}&prompt=${prompt}&response_type=${responseType}&client_id=${clientId}&scope=${scope}&access_type=${accessType}`;
    window.location.assign(oauthLink);
  }

  return (
    <Fragment>
      {/* <Descope
        flowId="sign-in-hrms"
        onSuccess={(e) => handleGoogleLoginSuccess(e)}
        onError={handleGoogleLoginError}
      /> */}
      <Button onClick={handleClick} variant="outlined" sx={{ width: '100%', background: '#000000' }}>
        <img height={32} src={googleSvg} alt="google-icon" />
        Login via Google
      </Button>
    </Fragment>
  )
}