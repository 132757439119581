// Working validations

import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Typography,
  Box,
  Stack,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Grid,
  Divider,
  Checkbox,
  Rating,
  TextareaAutosize,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import "./CulturalForm.css";
import getConfig from "../../helpers/getConfig";
import AppStepper from "../CulturalFormAppStepper";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { Snackbar, Alert } from "@mui/material";
import { setCookie } from "../../helpers/setCookie";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/system";
import { surveyEndpointMap } from "../../helpers/constants";
import AlertSnackbar from "../core/AlertSnackbar/AlertSnackbar";
import DialogStandard from "../core/DialogStandard/DialogStandard";
import StarIcon from '@mui/icons-material/Star';
import AlreadySubmitted from "../AlreadySubmitted/AlreadySubmitted";


const CulturalForm = ({ setBgImage }) => {
  const [formQuestions, setFormQuestions] = useState([]);
  const [feedbackIdentifier, setFeedbackIdentifier] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState({
    index: 0,
    title: "Culture Feedback",
  });
  const [sectionedQuestions, setSectionedQuestions] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [optionQuestionsMap, setOptionQuestionsMap] = useState({});
  const [optionIds, setOptionIds] = useState({});
  const [optionWeights, setOptionWeights] = useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const [details, setDetails] = useState({
    "Designation": "",
    "Band": "",
    "Department": "",
    "Name": "",
    "Employee ID": "",
    "Date of Joining": "",
    "Date": ""
  });
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [isUserDetailsFetched, setIsUserDetailsFetched] = useState(false);
  const [hasUserAlreadySubmitted, setHasUserAlreadySubmitted] = useState(false);
  const [nestedQuestions, setNestedQuestions] = useState({});
  const [renderNested, setRenderNested] = useState({});
  const surveyType = "Mission Elevate";

  // const backgroundImages = [img1, img2, img3, img4, img5, img6, img7, img8, img9];
  const [bgImageIndex, setBgImageIndex] = useState(-1);

  const backgroundImages = [
    "cultural-form-bg-0",
    "cultural-form-bg-1",
    "cultural-form-bg-2",
    "cultural-form-bg-3",
    "cultural-form-bg-4",
    "cultural-form-bg-5",
    "cultural-form-bg-6",
    "cultural-form-bg-7",
    "cultural-form-bg-8",
    "cultural-form-bg-9"
  ];


  const hostEnv = getConfig();

  const entity = hostEnv.entity;
  const endpoint = surveyEndpointMap[surveyType]?.[entity];

  useEffect(() => {
    const baseContainer = document.getElementById("base-container");

    const existingClass = Array.from(baseContainer.classList).find(
      (className) => className.startsWith("cultural-form-bg-")
    );

    if (existingClass) {
      baseContainer.classList.remove(existingClass);
    }

    baseContainer.classList.add(`cultural-form-bg-${currentSection?.index}`);

  }, [currentSection?.index, hasUserAlreadySubmitted]);

  useEffect(() => {
    axios
      .get(`${hostEnv.insightBaseUrl}/config/rating/hrms/culture-form`)
      .then((response) => {
        const data = response.data?.data;
        const questions = Object.values(data?.initialQuestions);
        const optionQuestionsMap = data?.optionQuestionsMap;
        const sortedQuestions = questions.sort(
          (a, b) => a.sequence - b.sequence
        );

        // Organize questions by section
        const sections = {};
        const initialValues = {};
        const optionIds = {};
        const optionWeights = {};

        sortedQuestions.forEach((question) => {
          const sectionTitle = question.metadata?.section?.title;
          if (!sections[sectionTitle]) {
            sections[sectionTitle] = [];
          }
          sections[sectionTitle].push(question);

          initialValues[question.id] = "";
          optionIds[question.id] = [];
          optionWeights[question.id] = {};
          question.options.forEach((option) => {
            optionIds[question.id].push(option.id);
            optionWeights[question.id][option.id] = option.weightage;
          });
        });

        console.log("SORTED", sortedQuestions);
        setFormQuestions(sortedQuestions);
        setSectionedQuestions(sections);
        setInitialValues(initialValues);
        setOptionIds(optionIds);
        setOptionWeights(optionWeights);
        setOptionQuestionsMap(optionQuestionsMap);
        setFeedbackIdentifier(data?.ssId);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching form questions:", error);
        setLoading(false);
      });
  }, [hostEnv.insightBaseUrl]);

  const sectionTitles = Object.keys(sectionedQuestions);

  const handleSubmit = async (values) => {
    const sectionErrors = validateSection(currentSection.title, values);

    if (Object.keys(sectionErrors).length === 0) {
      let answerList = [];
      console.log("FINAL", values);

      Object.keys(values).forEach((key) => {
        let questionId, optionId, comment;

        // Check if the key contains an underscore, indicating a questionId_optionId format
        if (key.includes("_")) {
          [questionId, optionId] = key.split("_");
          comment = values[key];
        } else {
          questionId = key;
          optionId = values[key];
          comment = "";
        }

        // Get the question type from formQuestions object
        const questionType = formQuestions.find(q => q.id === parseInt(questionId))?.type;

        let selectedOptionId, selectedOptionWeightage;

        if (questionType === "STAR") {
          selectedOptionId = optionIds[questionId][values[questionId] - 1];
          selectedOptionWeightage = optionWeights[questionId]?.[selectedOptionId] || 0;

          answerList.push({
            optionId: selectedOptionId.toString(),
            weightage: selectedOptionWeightage,
            comment: comment,
            metadata: {},
            questionId: questionId,
          });
        } else {
          // For other question types, use the default optionId and an empty weightage
          answerList.push({
            optionId: optionId,
            weightage: "",
            comment: comment,
            metadata: {},
            questionId: questionId,
          });
        }
      });

      console.log(answerList, "Answer List");

      const userDetails = JSON.parse(localStorage.getItem("user-details"));

      const feedbackResponse = {
        ssId: `${feedbackIdentifier}`,
        identifierId: uuidv4(),
        identifierType: "CULTURAL_FORM",
        feedbackGivenFor: uuidv4(),
        feedbackGivenForEntityType: `${hostEnv.entity}`,
        answer: answerList,
        feedbackGivenByMetadata: {
          externalId: "sdfdsf-34234-sdfsdf-234234",
        },
        feedbackGivenBy: `${userDetails?.Email}`,
        feedbackGivenByEntityType: "EMPLOYEE",
      };

      const headers = {
        "x-channel-name": "hrms_web",
        token: "Bearer token",
      };

      try {
        await axios.post(`${hostEnv.insightBaseUrl}/feedback`, feedbackResponse, {
          headers: headers,
        });

        setSubmitted(true);
        setHasUserAlreadySubmitted(true);

        try {
          await axios.post(
            'https://hrms-dbms-prod-6lptus6tza-el.a.run.app/ems/set_redis',
            {
              survey_id: feedbackIdentifier,
              emp_id: details?.["Employee ID"],
            }
          );
        } catch (error) {
          console.log("Error while setting details in redis", error);
        }

        const userEmail = JSON.parse(localStorage.getItem("user-details"))?.Email;
        setCookie("coi-employee-feedback", userEmail, 90);
        setCurrentSection((prev) => ({
          index: prev.index + 1,
          title: sectionTitles[prev.index + 1],
        }));
      } catch (error) {
        console.error("Error:", error);
        setToast({
          open: true,
          message: "Failed to submit feedback.",
          severity: "error",
        });
      }
    } else {
      setOpen(true); // Show validation dialog if there are errors
    }
  };

  const handleSkip = () => {
    setCurrentSection((prev) => ({
      index: prev.index + 1,
      title: sectionTitles[prev.index + 1],
    }));
  };

  const handleNext = (values, validateForm) => {
    const sectionErrors = validateSection(currentSection.title, values);
    if (Object.keys(sectionErrors).length === 0) {
      if (currentSection.index < sectionTitles.length - 1) {
        setCurrentSection((prev) => ({
          index: prev.index + 1,
          title: sectionTitles[prev.index + 1],
        }));
      }
    } else {
      setOpen(true); // Show validation dialog if there are errors
    }
  };

  const handleBack = () => {
    if (currentSection.index > 0) {
      setCurrentSection((prev) => ({
        index: prev.index - 1,
        title: sectionTitles[prev.index - 1],
      }));
    }
  };


  const validateSection = (sectionTitle, values) => {

    const sectionQuestions = sectionedQuestions[sectionTitle] || [];
    let errors = {};

    sectionQuestions.forEach((question) => {
      if (sectionTitle === "Candidate’s Detail") { 
        question.options.forEach((option) => {
          const fieldName = `${question.id}_${option.id}`;
          if (!values[fieldName]) {
            errors[fieldName] = "This field is required";
          }
        });
      }
    });

    return errors;
  };

  const handleChange = (questionId, value, values, setFieldValue) => {
    try {
      // Update the selected value
      setFieldValue(questionId, value);

      // Remove any questionId_optionId keys from values if the option is not present in optionQuestionsMap
      Object.keys(values).forEach((key) => {
        if (key.startsWith(`${questionId}_`)) {
          const optionId = key.split("_")[1];
          // console.log(optionId, "optionss")
          if (!optionQuestionsMap[optionId]) {
            setFieldValue(key, undefined); // Removes the key from Formik values
          }
        }
      });
    } catch (error) {
      console.error("Error in handleChange:", error.message);
    }
  };



  const handleClose = () => {
    setOpen(false);
  };

  const showFormImage = window.innerWidth >= 1440;

  const getUserInformation = () => {
    const sessionToken = localStorage.getItem("accessToken");

    if (sessionToken) {
      const decoded = jwtDecode(sessionToken);
      const email = decoded?.email;

      axios
        .get(`${hostEnv.employeeBaseUrl}/fetch_employee`, {
          params: {
            email: email,
            survey_id: feedbackIdentifier,
          },
        })
        .then((response) => {
          const data = response?.data?.data;
          const message = response?.data?.message;
          if (data) {
            // console.log(data, 'data');

            let name = "";

            if (data?.first_name && data?.last_name) {
              name = `${data?.first_name} ${data?.last_name}`;
            } else if (data?.first_name) {
              name = `${data?.first_name}`;
            }

            const payload = {
              Name: name,
              Department: data?.department_name,
              Band: data?.band,
              "Date of Joining": dayjs(data?.date_of_joining).format(
                "DD/MM/YYYY"
              ),
              Designation: data?.designation,
              "Employee ID": data?.employee_id,
              Email: data?.company_email_id,
            };

            setDetails((prev) => ({
              ...prev,
              Name: name,
              Department: data?.department_name,
              Band: data?.band,
              "Date of Joining": dayjs(data?.date_of_joining).format(
                "DD/MM/YYYY"
              ),
              Designation: data?.designation,
              "Employee ID": data?.employee_id,
              Date: dayjs().format("DD/MM/YYYY"),
            }));
            localStorage.setItem("user-details", JSON.stringify(payload));
            setIsUserDetailsFetched(true);
          } else if (message) {
            setHasUserAlreadySubmitted(true);
            setIsUserDetailsFetched(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsUserDetailsFetched(true);
        });
    }
  };

  useEffect(() => {
    if (feedbackIdentifier) {
      getUserInformation();
    }
  }, [feedbackIdentifier]);

  const title = currentSection?.title;
  const sectionData = sectionedQuestions[title] && sectionedQuestions[title][0];
  const metadata = sectionData?.metadata?.section;

  const metaTitle = metadata?.title;
  const metaDesc = metadata?.description;
  const metaDesc2 = metadata?.description2;
  const metaDesc3 = metadata?.description3;
  const metaList = metadata?.details;
  const staticContent = metadata?.staticContent;
  const key = sectionData?.metadata?.showTitle;
  console.log(metaDesc, "desc")
  const isAlreadySubmitted = hasUserAlreadySubmitted;



  return (
    <Fragment>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Fragment>
            <Box
              className="form-wrapper-cultural-form"
              p={{ xs: 2, sm: 4 }}
              sx={{
                width: "100%",
                maxWidth: "1200px",
                margin: "auto",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: 4,
                color: "white",
                "& .MuiTypography-root": {
                  color: "white",
                },
                "& .MuiButton-root": {
                  borderRadius: 2,
                },
              }}
            >
              {
                loading ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <Form className="form-card-cultural-form">
                    <Fragment>
                      <AlertSnackbar
                        open={toast.open}
                        message={toast.message}
                        severity={toast.severity}
                        autoHideDuration={6000}
                        onClose={() => setToast({ ...toast, open: false })}
                      />
                      <Box p={{ xs: 2, sm: 4 }}>
                        <AppStepper currentIndex={currentSection.index} />
                      </Box>
                      <Box p={{ xs: 2, sm: 6 }} sx={{ paddingBottom: '100px !important' }}>
                        <Fragment>
                          {(metaDesc2 || metaDesc3) && (
                            <Typography mb={2} textAlign={"left"} color={"#FFFFFF"}>
                              {metaDesc2 && (
                                <>
                                  {metaDesc2}
                                </>
                              )}
                              {metaDesc3}
                            </Typography>
                          )}
                          <Box pl={2}>
                            <ul
                              style={{
                                textAlign: "left",
                                marginBottom: "2px",
                                color: "#FFFFFF",
                              }}
                            >
                              {metaList?.map((item, index) => (
                                <li
                                  key={index}
                                  style={{
                                    marginBottom: "8px",
                                    fontSize: "14px",
                                    color: "#FFFFFF",
                                    paddingLeft: "16px",
                                  }}
                                >
                                  {item?.key}: {item?.value}
                                </li>
                              ))}
                            </ul>
                          </Box>
                          <Box>
                            {staticContent?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    textAlign: "center",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    style={{ color: item?.heading === "Hey Autonaut" ? "#FFFFF" : "#F7931D" }}
                                    mb={2}
                                  >
                                    {item?.heading}
                                  </Typography>

                                  <Typography
                                    mb={2}
                                    textAlign={"center"}
                                    color={"#FFFFFF"}
                                    fontSize={"14px"}
                                    lineHeight={"20px"}
                                  >
                                    {item?.description && (
                                      <>
                                        {item.description}
                                        <br />
                                        <br />
                                      </>
                                    )}
                                    {item?.description1 && (
                                      <>
                                        {item.description1}
                                        <br />
                                        <br />
                                      </>
                                    )}
                                    {item?.description2 && (
                                      <>
                                        {item.description2}
                                        <br />
                                        <br />
                                      </>
                                    )}
                                    {item?.description3 && (
                                      <>
                                        {item.description3}
                                        <br />
                                        <br />
                                      </>
                                    )}
                                    {item?.description4 && (
                                      <>
                                        {item.description4}
                                        <br />
                                        <br />
                                      </>
                                    )}
                                    {item?.description5 && (
                                      <>
                                        {item.description5}
                                        <br />
                                        <br />
                                      </>
                                    )}
                                    {item?.description6}
                                  </Typography>
                                </div>
                              );
                            })}
                          </Box>

                          <Stack spacing={4} sx={{ alignContent: "center", textAlign: "center" }}>
                            {sectionedQuestions[currentSection.title]?.map(
                              (question) => (
                                <Box
                                  sx={{ color: "#FFFFFF" }}
                                  className="question-box"
                                  key={question.id}

                                >
                                  {!question?.metadata?.showName &&
                                    question?.metadata?.showName !== false ? (
                                    <Typography
                                      fontFamily="Roboto"
                                      fontWeight={500}
                                      sx={{
                                        width: "100%",
                                        maxWidth: "966px",
                                        fontFamily: "Inter",
                                        fontSize: "18px",
                                        fontWeight: "500",
                                        lineHeight: "21.78px",
                                        textAlign: "center",
                                      }}

                                      mb={1}
                                    >

                                      {question.name}
                                    </Typography>
                                  ) : null}

                                  <Field name={question.id}>
                                    {({ field }) => (
                                      <>
                                        {!question?.metadata?.showOptions && question?.metadata?.showOptions !== false && question.type === "SINGLE_SELECTION" ? (
                                          <RadioGroup
                                            {...field}
                                            name={question.id}
                                            value={values[question.id]}
                                            onChange={(event) => {
                                              setIsChecked(true);
                                              handleChange(question.id, event.target.value, values, setFieldValue);
                                            }}
                                          >
                                            {question.options
                                              .sort((a, b) => a.sequence - b.sequence)
                                              .map((option) => {
                                                return (
                                                  <FormControlLabel
                                                    key={option.id}
                                                    value={option.id}
                                                    control={<Radio />}
                                                    label={(option?.toolTip || "") + " " + (option?.metadata?.description || "")}
                                                  />
                                                );
                                              })}
                                          </RadioGroup>
                                        ) : !question?.metadata?.showOptions && question?.metadata?.showOptions !== false && question.type === "TEXT" ? (
                                          <Grid container spacing={2} className="cultrual__form--muiGrid-container">
                                            {question.options
                                              .sort((a, b) => a.sequence - b.sequence)
                                              .map((option) => {
                                                const tooltip = option?.toolTip;
                                                const userDetailValue = details[tooltip];
                                                const optionType = option?.metadata?.type;
                                                const showOptions = question?.metadata?.showOptions !== false;
                                                const isDeclarationSection = title === "Thank You";

                                                const isPresent = Object.keys(values).find((item) => item === `${question.id}_${option.id}`);

                                                if (!isPresent && !isDeclarationSection && isUserDetailsFetched) {
                                                  if (userDetailValue) {
                                                    setFieldValue(`${question.id}_${option.id}`, userDetailValue);
                                                  }
                                                }

                                                return (

                                                  <Grid item xs={12} sm={6} key={option.id} className="cultural__form--muiGrid">
                                                    <Box mb={2}>
                        
                                                      {optionType === "CHECKBOX" ? (
                                                        <FormControlLabel
                                                          control={
                                                            <Checkbox
                                                              checked={values[`${question.id}_${option.id}`] || false}
                                                              onChange={(event) => {
                                                                setFieldValue(`${question.id}_${option.id}`, event.target.checked);
                                                                setIsChecked(event.target.checked);
                                                              }}
                                                            />
                                                          }
                                                          label={option.toolTip}
                                                        />
                                                      ) : (
                                                        option.metadata.type === 'TEXTAREA' ? (
                                                          <div className="cultural__form--textArea-container">
                                                            <TextareaAutosize
                                                              className="text-area-component"
                                                              name={`${question.id}_${option.id}`}
                                                              value={
                                                                values[
                                                                `${question.id}_${option.id}`
                                                                ]
                                                              }
                                                              maxLength={200}
                                                              onChange={(event) =>
                                                                setFieldValue(
                                                                  `${question.id}_${option.id}`,
                                                                  event.target.value
                                                                )
                                                              }
                                                              placeholder={option.toolTip}
                                                            />
                                                          </div>
                                                        ) : (
                                                          option.allowComment && (
                                                            <div className="cultural__form--textField-container" style={{ marginBottom: '10px',marginLeft:'5px' }}> 
                                                            <Typography
                                                                variant="subtitle1" 
                                                                component="label"
                                                                htmlFor={option.toolTip} 
                                                                sx={{ color: 'gray', marginBottom: '4px' }}
                                                              >
                                                                {option.toolTip} 
                                                              </Typography>
                                                            <TextField
                                                              {...field}
                                                              id={option.toolTip}
                                                              disabled={userDetailValue}
                                                              className="text-field"
                                                              name={`${question.id}_${option.id}`}
                                                              value={userDetailValue || values[`${question.id}_${option.id}`]}
                                                              onChange={(event) =>
                                                                setFieldValue(`${question.id}_${option.id}`, event.target.value)
                                                              }
                                                              variant="outlined"

                                                              size="small"
                                                              placeholder={option.toolTip}
                                                              sx={{ maxWidth: window.innerWidth < 1024 ? 'auto' : '460px', display: "flex", justifyContent: "center", color: "gray", backgroundColor: "white", borderRadius: "0.7rem", }}
                                                            />
                                                            </div>
                                                          )
                                                        )
                                                      )}
                                                    </Box>
                                                  </Grid>

                                                );
                                              })}
                                          </Grid>
                                        ) : question.type === "STAR" ? (
                                          <div style={{ justifyContent: "center", textAlign: 'center' }}>
                                            {metaTitle !== "Employee Details" && (
                                              <Typography sx={{ margin: '' }} textAlign={"center"} color={"#FFFFFF"}>
                                                {metaDesc}
                                              </Typography>
                                            )}
                                            <Rating
                                              {...field}
                                              name={question.id}
                                              value={values[question.id]}
                                              onChange={(event, newValue) => {
                                                setFieldValue(question.id, newValue);
                                              }}
                                              emptyIcon={
                                                <StarIcon
                                                  style={{ opacity: 0.55, color: "gray" }} 
                                                  fontSize="inherit"
                                                />
                                              }
                                              size="large"
                                              precision={1}
                                              max={5}
                                              sx={{ color: "rgba(250, 200, 23, 1)" }} 
                                            />
                                          </div>
                                        ) : null}
                                      </>
                                    )}
                                  </Field>
                                </Box>
                              )
                            )}
                            <Box  width="100%" sx={{
                              display: { xs: 'flex', sm: 'block',md:'flex' },
                              flexDirection: { xs: 'column', sm: 'row', md:'row' }, 
                              justifyContent:'center',
                              alignItems: 'center', 
                              gap: '15px', 
                            }}>
                              {(currentSection.index !== 0 && currentSection.index !== 9) && (
                                <Button
                                  type="button"
                                  variant="outlined"
                                  color="primary"
                                  onClick={handleBack}
                                  sx={{
                                    marginRight: window.innerWidth < 1024 ? '' : '12px',
                                    marginTop: "18px",
                                    width: "134px",
                                    border:'none',
                                    borderRadius: "var(--c-button-small-corner-radius)",
                                    backgroundColor: "rgba(151, 151, 151, 1)",
                                    color: "white",
                                    "&:hover": {
                                      backgroundColor: "#696969",
                                    },
                                  }}
                                >
                                  Back
                                </Button>
                              )}
                              {(currentSection?.index !== 9 &&
                                !((currentSection?.index === 0 || currentSection?.index === 1) && currentSection.title !== "Put the Organization Above Self" && currentSection.title !== "Thank You")) && (
                                  <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSkip()}
                                    sx={{
                                      marginTop: "18px",
                                      marginRight: window.innerWidth < 1024 ? '' : '8px',

                                      width: "134px",
                                      borderRadius: "var(--c-button-small-corner-radius)",
                                      backgroundColor: "rgba(1, 113, 188, 1)",
                                    }}

                                  >
                                    Skip
                                  </Button>
                                )}
                              {currentSection.title !== "Put the Organization Above Self" && currentSection.title !== "Thank You" && (
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    handleNext(values, validateSection)
                                  }
                                  sx={{
                                    marginTop: "18px",
                                    marginRight: window.innerWidth < 1024 ? '' : '2',
                                    marginLeft: window.innerWidth < 1024 ? '' : '4px',
                                    width: "134px",
                                    borderRadius: "var(--c-button-small-corner-radius)",
                                    backgroundColor: "#F7931D",
                                  }}

                                >
                                  {currentSection?.index === 0 ? "Let's Go" : "Save"}
                                </Button>
                              )}
                              {currentSection.title === "Put the Organization Above Self" && (
                                <Fragment>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      marginTop: "18px",
                                      width: "130px",
                                      borderRadius: "var(--c-button-small-corner-radius)",
                                      backgroundColor: "#e67e22",
                                      "&:hover": {
                                        backgroundColor: "#e67e22",
                                      },
                                    }}

                                  // disabled={!isChecked}
                                  >
                                    Submit
                                  </Button>
                                </Fragment>
                              )}
                            </Box>
                          </Stack>
                        </Fragment>
                      </Box>
                    </Fragment>
                  </Form>
                )}
              <DialogStandard
                open={open}
                handleClose={handleClose}
                title="Oops!"
                message="Looks like you’ve left some celestial queries unanswered. Complete the current mission log to navigate to your next astral adventure!"
                buttonText="GOT IT"
              />
            </Box>
          </Fragment >
        )
        }
      </Formik >
    </Fragment >
  );
};

export default CulturalForm;
