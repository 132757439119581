// Working validations

import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Typography,
  Box,
  Stack,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Grid,
  Divider,
  Checkbox,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import "./MissionElevate.css";
import getConfig from "../../helpers/getConfig";
import AppStepper from "../AppStepper1";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { Snackbar, Alert } from "@mui/material";
import { setCookie } from "../../helpers/setCookie";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/system";
import astronautImage from "../../images/astronaut.png";
import astronautImageDap from "../../images/Group 15 (1).png";
import { surveyEndpointMap } from "../../helpers/constants";
import JobListingMissionElevate from "./JobListingPage/JobListingMissionElevate";
import AlertSnackbar from "../core/AlertSnackbar/AlertSnackbar";
import DialogStandard from "../core/DialogStandard/DialogStandard";


const MissionElevate = () => {
  const [formQuestions, setFormQuestions] = useState([]);
  const [feedbackIdentifier, setFeedbackIdentifier] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState({
    index: 0,
    title: "Mission Elevate",
  });
  const [sectionedQuestions, setSectionedQuestions] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [optionQuestionsMap, setOptionQuestionsMap] = useState({});
  const [optionIds, setOptionIds] = useState({});
  const [optionWeights, setOptionWeights] = useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const [details, setDetails] = useState({
    "Designation": "",
    "Band": "",
    "Department": "",
    "Name": "",
    "Employee ID": "",
    "Date of Joining": "",
    "Date": ""
  });
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [isUserDetailsFetched, setIsUserDetailsFetched] = useState(false);
  const [hasUserAlreadySubmitted, setHasUserAlreadySubmitted] = useState(false);
  const [nestedQuestions, setNestedQuestions] = useState({});
  const [renderNested, setRenderNested] = useState({});
  const surveyType = "Mission Elevate";

  const reqId = urlParams.get("req_id");
  const applicantName = urlParams.get("name");

  const hostEnv = getConfig();

  const entity = hostEnv.entity;
  const endpoint = surveyEndpointMap[surveyType]?.[entity];

  useEffect(() => {
    axios
      .get(`${hostEnv.insightBaseUrl}/config/rating/hrms/${endpoint}`)
      .then((response) => {
        const data = response.data?.data;
        const questions = Object.values(data?.initialQuestions);
        const optionQuestionsMap = data?.optionQuestionsMap;
        const nestedQuestions = Object.values(data?.nestedQuestions);
        const sortedQuestions = questions.sort(
          (a, b) => a.sequence - b.sequence
        );
        const sortedNestedQuestions = nestedQuestions.sort(
          (a, b) => a.sequence - b.sequence
        );

        // Organize questions by section
        const sections = {};
        const initialValues = {};
        const optionIds = {};
        const optionWeights = {};

        sortedQuestions.forEach((question) => {
          const sectionTitle = question.metadata?.section?.title;
          if (!sections[sectionTitle]) {
            sections[sectionTitle] = [];
          }
          sections[sectionTitle].push(question);

          initialValues[question.id] = "";
          optionIds[question.id] = [];
          optionWeights[question.id] = {};
          question.options.forEach((option) => {
            optionIds[question.id].push(option.id);
            optionWeights[question.id][option.id] = option.weightage;
          });
        });
        sortedNestedQuestions.forEach((question) => {
          const sectionTitle = question.metadata?.section?.title;
          if (!sections[sectionTitle]) {
            sections[sectionTitle] = [];
          }
          sections[sectionTitle].push(question);

          initialValues[question.id] = "";
          optionIds[question.id] = [];
          optionWeights[question.id] = {};
          question.options.forEach((option) => {
            optionIds[question.id].push(option.id);
            optionWeights[question.id][option.id] = option.weightage;
          });
        });
        setFormQuestions(sortedQuestions);
        setSectionedQuestions(sections);
        setInitialValues(initialValues);
        setOptionIds(optionIds);
        setOptionWeights(optionWeights);
        setOptionQuestionsMap(optionQuestionsMap);
        setFeedbackIdentifier(data?.ssId);
        setLoading(false);
        setNestedQuestions(sortedNestedQuestions);
      })
      .catch((error) => {
        console.error("Error fetching form questions:", error);
        setLoading(false);
      });
  }, [hostEnv.insightBaseUrl]);

  const sectionTitles = Object.keys(sectionedQuestions);

  const handleSubmit = async (values) => {
    const sectionErrors = validateSection(currentSection.title, values);
    if (Object.keys(sectionErrors).length === 0) {
      let answerList = [];
      console.log("FINAL", values);

      console.log(values, "valuessss");

      // Loop through each key in values
      Object.keys(values).forEach((key) => {
        let questionId, optionId, comment;

        // Check if the key contains an underscore, indicating a questionId_optionId format
        if (key.includes("_")) {
          [questionId, optionId] = key.split("_");
          comment = values[key];
        } else {
          questionId = key;
          optionId = values[key];
          comment = "";
        }

        // Get the selected option weightage
        const selectedOptionWeightage = optionWeights[questionId][optionId];

        // Push the entry into the answerList
        answerList.push({
          optionId: optionId,
          weightage: "",
          comment: comment,
          metadata: {},
          questionId: questionId,
        });
      });
      answerList.forEach((answer) => {
        if (answer.questionId === "61" && answer.optionId === "299") {
          answer.weightage = 1;
        }
        if (answer.questionId === "62" && answer.optionId === "301") {
          answer.weightage = 1;
        }
      });

      console.log(answerList, "Answer List");
      const userDetails = JSON.parse(localStorage.getItem("user-details"));

      const feedbackResponse = {
        ssId: `${feedbackIdentifier}`,
        identifierId: `${reqId}`,
        identifierType: "MISSION_FEEDBACK",
        feedbackGivenFor: uuidv4(),
        feedbackGivenForEntityType: `${hostEnv.entity}`,
        answer: answerList,
        feedbackGivenByMetadata: {
          externalId: "sdfdsf-34234-sdfsdf-234234",
        },
        feedbackGivenBy: `${userDetails?.Email}`,
        feedbackGivenByEntityType: "EMPLOYEE",
      };

      const headers = {
        "x-channel-name": "hrms_web",
        token: "Bearer token",
      };

      try {
        await axios.post(
          `${hostEnv.insightBaseUrl}/feedback`,
          feedbackResponse,
          {
            headers: headers,
          }
        );

        setSubmitted(true);
        setHasUserAlreadySubmitted(true);

        try {
          await axios.post(
            'https://hrms-dbms-prod-6lptus6tza-el.a.run.app/ems/set_redis',
            {
              survey_id: feedbackIdentifier,
              emp_id: details?.["Employee ID"],
            }
          );
        } catch (error) {
          console.log("Error while setting details in redis", error);
        }

        const userEmail = JSON.parse(
          localStorage.getItem("user-details")
        )?.Email;
        setCookie("coi-employee-feedback", userEmail, 90);
        setCurrentSection((prev) => ({
          index: prev.index + 1,
          title: sectionTitles[prev.index + 1],
        }));
      } catch (error) {
        console.error("Error:", error);
        setToast({
          open: true,
          message: "Failed to submit feedback.",
          severity: "error",
        });
      }
    } else {
      setOpen(true); // Show validation dialog if there are errors
    }
  };

  const handleNext = (values, validateForm) => {
    const sectionErrors = validateSection(currentSection.title, values);
    if (Object.keys(sectionErrors).length === 0) {
      if (currentSection.index < sectionTitles.length - 1) {
        setCurrentSection((prev) => ({
          index: prev.index + 1,
          title: sectionTitles[prev.index + 1],
        }));
      }
    } else {
      setOpen(true); // Show validation dialog if there are errors
    }
  };

  const validateSection = (sectionTitle, values) => {
    const sectionQuestions = sectionedQuestions[sectionTitle] || [];
    let errors = {};

    sectionQuestions.forEach((question) => {
      // console.log(values, "HEDE")
      // Check if the main question is answered
      const EXCEPTED_SECTIONS = ["Employee Details", "Mission Elevate", "Confirm-it"];
      console.log(question?.metadata, "question")
      const isValidationRequired = question?.metadata?.isValidationRequired;
      console.log(isValidationRequired, "isValidationRequired")

      if (!EXCEPTED_SECTIONS.includes(sectionTitle) && !values[question.id]) {
        errors[question.id] = "This field is required";
      }

      if (sectionTitle === "Employee Details") {
        // Check if any combined `question.id_option.id` value is empty
        question.options.forEach((option) => {
          const fieldName = `${question.id}_${option.id}`;
          if (!values[fieldName]) {
            errors[fieldName] = "This field is required";
          }
        });
      }

      // If the question has a nested question and it is rendered, validate it
      if (renderNested[question.id]?.render) {
        const nestedQuestionId =
          renderNested[question.id]?.data?.nestedQuestionId;
        const nestedQuestion = nestedQuestions.find(
          (q) => q.id === nestedQuestionId
        );

        if (nestedQuestion) {
          // Validate the nested question options
          nestedQuestion.options.forEach((option) => {
            console.log(option, "option")
            const nestedFieldName = `${question.id}_${option.id}`;
            if ((option.allowComment && option?.metadata?.isValidationRequired)
              && !values[nestedFieldName]) {
              errors[nestedFieldName] = "This field is required";
            }
          });
        }
      }
    });

    return errors;
  };

  const handleChange = (questionId, value, values, setFieldValue) => {
    try {
      // Update the selected value
      setFieldValue(questionId, value);

      // console.log('Initial Values:', values);

      let isPresent = false;
      let additionalData = null;

      // Get the value associated with the key from optionQuestionsMap
      const valueToCheck = optionQuestionsMap[value]
        ? optionQuestionsMap[value][0]
        : null;

      if (valueToCheck === null) {
        isPresent = false;
      } else {
        // Extract all 'id' values from objects in nestedQuestions
        const isNestedQuestion = nestedQuestions.map((item) => item.id);

        // Check if the valueToCheck is in idsInB
        isPresent = isNestedQuestion.includes(valueToCheck);
        additionalData = isPresent ? { nestedQuestionId: valueToCheck } : null;
      }

      // Remove any questionId_optionId keys from values if the option is not present in optionQuestionsMap
      Object.keys(values).forEach((key) => {
        if (key.startsWith(`${questionId}_`)) {
          const optionId = key.split("_")[1];
          // console.log(optionId, "optionss")
          if (!optionQuestionsMap[optionId]) {
            setFieldValue(key, undefined); // Removes the key from Formik values
          }
        }
      });

      console.log(isPresent, "isPresent", additionalData, "additionalData");
      setRenderNested((prevState) => ({
        ...prevState,
        [questionId]: {
          render: isPresent,
          data: additionalData,
        },
      }));
    } catch (error) {
      console.error("Error in handleChange:", error.message);
    }
  };

  const handleBack = () => {
    if (currentSection.index > 0) {
      setCurrentSection((prev) => ({
        index: prev.index - 1,
        title: sectionTitles[prev.index - 1],
      }));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showFormImage = window.innerWidth >= 1440;

  const getUserInformation = () => {
    const sessionToken = localStorage.getItem("accessToken");

    if (sessionToken) {
      const decoded = jwtDecode(sessionToken);
      const email = decoded?.email;

      axios
        .get(`${hostEnv.employeeBaseUrl}/fetch_employee`, {
          params: {
            email: email,
            survey_id: feedbackIdentifier,
          },
        })
        .then((response) => {
          const data = response?.data?.data;
          const message = response?.data?.message;
          if (data) {
            // console.log(data, 'data');

            let name = "";

            if (data?.first_name && data?.last_name) {
              name = `${data?.first_name} ${data?.last_name}`;
            } else if (data?.first_name) {
              name = `${data?.first_name}`;
            }

            const payload = {
              Name: name,
              Department: data?.department_name,
              Band: data?.band,
              "Date of Joining": dayjs(data?.date_of_joining).format(
                "DD/MM/YYYY"
              ),
              Designation: data?.designation,
              "Employee ID": data?.employee_id,
              Email: data?.company_email_id,
            };

            setDetails((prev) => ({
              ...prev,
              Name: name,
              Department: data?.department_name,
              Band: data?.band,
              "Date of Joining": dayjs(data?.date_of_joining).format(
                "DD/MM/YYYY"
              ),
              Designation: data?.designation,
              "Employee ID": data?.employee_id,
              Date: dayjs().format("DD/MM/YYYY"),
            }));
            localStorage.setItem("user-details", JSON.stringify(payload));
            setIsUserDetailsFetched(true);
          } else if (message) {
            setHasUserAlreadySubmitted(true);
            setIsUserDetailsFetched(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsUserDetailsFetched(true);
        });
    }
  };

  useEffect(() => {
    if (feedbackIdentifier) {
      getUserInformation();
    }
  }, [feedbackIdentifier]);

  const title = currentSection?.title;
  const sectionData = sectionedQuestions[title] && sectionedQuestions[title][0];
  const metadata = sectionData?.metadata?.section;

  const metaTitle = metadata?.title;
  const metaDesc = metadata?.description;
  const metaDesc2 = metadata?.description2;
  const metaDesc3 = metadata?.description3;
  const metaList = metadata?.details;
  const staticContent = metadata?.staticContent;
  const key = sectionData?.metadata?.showTitle;

  const isAlreadySubmitted = hasUserAlreadySubmitted;

  const AstronautImage = styled("img")(({ theme, progress }) => {
    console.log(progress, "hello")

    const isAtEnd = progress === 100;

    return {
      position: "absolute",
      bottom: 0,
      marginRight: "40px",
      marginBottom: "40px",
      [isAtEnd ? "right" : "left"]: isAtEnd ? 0 : `calc(${progress}%)`,
      transition: "left 0.5s ease-in-out, right 0.5s ease-in-out",
      height: 100,
      zIndex: 1,
    };
  });

  const totalSteps = 9;
  const showAstronautImageDap = currentSection.index + 1 >= totalSteps || isAlreadySubmitted;
  const astronautProgress = showAstronautImageDap ? 100 : (currentSection.index / totalSteps) * 100;
  const astronautImageSrc = showAstronautImageDap ? astronautImageDap : astronautImage;

  const isMobile = window.innerWidth < 1024;

  return (
    <Fragment>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Box
            className="form-wrapper-mission-elevate"
            p={{ xs: 2, sm: 4 }}
            sx={{
              width: "100%",
              maxWidth: "1200px",
              margin: "auto",
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: 4,
              color: "white",
              "& .MuiTypography-root": {
                color: "white",
              },
              "& .MuiButton-root": {
                borderRadius: 2,
              },
            }}
          >
            <AstronautImage
              style={{ margin: "5px" }}
              src={astronautImageSrc}
              alt="Astronaut"
              progress={astronautProgress}
            />
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Form className="form-card-mission-elevate">
                <Fragment>
                  <AlertSnackbar
                    open={toast.open}
                    message={toast.message}
                    severity={toast.severity}
                    autoHideDuration={6000}
                    onClose={() => setToast({ ...toast, open: false })}
                  />
                  <Box p={{ xs: 2, sm: 4 }}>
                    <AppStepper
                      sectionedQuestions={sectionedQuestions}
                      currentIndex={currentSection.index}
                      title={currentSection.title}
                      values={values}
                      isAlreadySubmitted={isAlreadySubmitted}
                    />
                  </Box>
                  <Divider
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.12)" }}
                  />
                  <Box sx={{ paddingX: isMobile ? "0px" : "24px", paddingBottom: '100px !important' }}>
                    {isAlreadySubmitted ? (
                      <JobListingMissionElevate setCurrentSection={setCurrentSection} sectionTitles={sectionTitles} />
                    ) : (
                      <Fragment>
                        <Typography textAlign={"left"} color={"#FFFFFF"}>
                          {key && (
                            <strong style={{ color: "#F7931E" }}>
                              {metaTitle === "Employee Details"
                                ? null
                                : `${metaTitle}`}
                            </strong>
                          )}
                          {metaDesc}
                        </Typography>
                        {(metaDesc2 || metaDesc3) && (
                          <Typography mb={2} textAlign={"left"} color={"#FFFFFF"}>
                            {metaDesc2 && (
                              <>
                                {metaDesc2}
                              </>
                            )}
                            {metaDesc3}
                          </Typography>
                        )}
                        <Box pl={2}>
                          <ul
                            style={{
                              textAlign: "left",
                              marginBottom: "2px",
                              color: "#FFFFFF",
                            }}
                          >
                            {metaList?.map((item, index) => (
                              <li
                                key={index}
                                style={{
                                  marginBottom: "8px",
                                  fontSize: "14px",
                                  color: "#FFFFFF",
                                  paddingLeft: "16px",
                                }}
                              >
                                {item?.key}: {item?.value}
                              </li>
                            ))}
                          </ul>
                        </Box>
                        <Box>
                          {staticContent?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  textAlign: "left",
                                  marginBottom: "16px",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ color: item?.heading === "Hello Autonaut," ? "#0557AB" : "#F7931D" }}
                                  mb={2}
                                >
                                  {item?.heading}
                                </Typography>

                                <Typography
                                  mb={2}
                                  textAlign={"left"}
                                  color={"#FFFFFF"}
                                  fontSize={"14px"}
                                  lineHeight={"20px"}
                                >
                                  {item?.description && (
                                    <>
                                      {item.description}
                                      <br />
                                      <br />
                                    </>
                                  )}
                                  {item?.description1 && (
                                    <>
                                      {item.description1}
                                      <br />
                                      <br />
                                    </>
                                  )}
                                  {item?.description2 && (
                                    <>
                                      {item.description2}
                                      <br />
                                      <br />
                                    </>
                                  )}
                                  {item?.description3 && (
                                    <>
                                      {item.description3}
                                      <br />
                                      <br />
                                    </>
                                  )}
                                  {item?.description4 && (
                                    <>
                                      {item.description4}
                                      <br />
                                      <br />
                                    </>
                                  )}
                                  {item?.description5 && (
                                    <>
                                      {item.description5}
                                      <br />
                                      <br />
                                    </>
                                  )}
                                  {item?.description6}
                                </Typography>
                              </div>
                            );
                          })}
                        </Box>

                        <Stack spacing={4}>
                          {sectionedQuestions[currentSection.title]?.map(
                            (question) => (
                              <Box
                                sx={{ color: "#FFFFFF" }}
                                className="question-box"
                                key={question.id}
                              >
                                <Typography
                                  fontFamily="Roboto"
                                  fontWeight={500}
                                  mb={1}
                                >
                                  {!question?.metadata?.showName &&
                                    question?.metadata?.showName !== false ? (
                                    <>{question.name}</>
                                  ) : null}
                                </Typography>

                                <Field name={question.id}>
                                  {({ field }) => (
                                    <>
                                      {!question?.metadata?.showOptions &&
                                        question?.metadata?.showOptions !==
                                        false &&
                                        question.type === "SINGLE_SELECTION" ? (
                                        <RadioGroup
                                          {...field}
                                          name={question.id}
                                          value={values[question.id]}
                                          onChange={(event) => {
                                            setIsChecked(true);
                                            handleChange(
                                              question.id,
                                              event.target.value,
                                              values,
                                              setFieldValue
                                            );
                                          }}
                                        >
                                          {question.options
                                            .sort(
                                              (a, b) => a.sequence - b.sequence
                                            )
                                            .map((option) => {
                                              return (
                                                <FormControlLabel
                                                  key={option.id}
                                                  value={option.id}
                                                  control={<Radio />}
                                                  label={
                                                    (option?.toolTip || "") +
                                                    " " +
                                                    (option?.metadata
                                                      ?.description || "")
                                                  }
                                                />
                                              );
                                            })}
                                        </RadioGroup>
                                      ) : !question?.metadata?.showOptions &&
                                        question?.metadata?.showOptions !==
                                        false &&
                                        question.type === "TEXT" ? (
                                        <Grid container spacing={2}>
                                          {question.options
                                            .sort(
                                              (a, b) => a.sequence - b.sequence
                                            )
                                            .map((option) => {
                                              const tooltip = option?.toolTip;
                                              const userDetailValue =
                                                details[tooltip];
                                              const optionType =
                                                option?.metadata?.type;
                                              const showOptions =
                                                question?.metadata
                                                  ?.showOptions !== false;
                                              const isDeclarationSection =
                                                title === "Confirm-it";

                                              const isPresent = Object.keys(
                                                values
                                              ).find(
                                                (item) =>
                                                  item ===
                                                  `${question.id}_${option.id}`
                                              );

                                              if (
                                                !isPresent &&
                                                !isDeclarationSection &&
                                                isUserDetailsFetched
                                              ) {
                                                if (userDetailValue) {
                                                  setFieldValue(
                                                    `${question.id}_${option.id}`,
                                                    userDetailValue
                                                  );
                                                }
                                              }

                                              return (
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={6}
                                                  key={option.id}
                                                >
                                                  <Box mb={2}>
                                                    <Typography
                                                      fontFamily="Roboto"
                                                      fontWeight={500}
                                                      mb={1}
                                                      color="white"
                                                    >
                                                      {optionType !==
                                                        "CHECKBOX" &&
                                                        option?.toolTip}{" "}
                                                      {/* <span
                                                        style={{ color: "red" }}
                                                      >
                                                        {optionType !==
                                                          "CHECKBOX" && "*"}
                                                      </span> */}
                                                    </Typography>
                                                    {optionType ===
                                                      "CHECKBOX" ? (
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={
                                                              values[
                                                              `${question.id}_${option.id}`
                                                              ] || false
                                                            }
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              setFieldValue(
                                                                `${question.id}_${option.id}`,
                                                                event.target
                                                                  .checked
                                                              );
                                                              setIsChecked(
                                                                event.target
                                                                  .checked
                                                              );
                                                            }}
                                                          />
                                                        }
                                                        label={option.toolTip}
                                                      />
                                                    ) : (
                                                      option.allowComment && (
                                                        <TextField
                                                          {...field}
                                                          disabled={
                                                            userDetailValue
                                                          }
                                                          className="text-field"
                                                          name={`${question.id}_${option.id}`}
                                                          value={
                                                            userDetailValue ||
                                                            values[
                                                            `${question.id}_${option.id}`
                                                            ]
                                                          }
                                                          onChange={(event) =>
                                                            setFieldValue(
                                                              `${question.id}_${option.id}`,
                                                              event.target.value
                                                            )
                                                          }
                                                          variant="outlined"
                                                          fullWidth
                                                          size="small"
                                                          placeholder={
                                                            option.toolTip
                                                          }
                                                          sx={{
                                                            input: {
                                                              color: "white",
                                                            },
                                                          }}
                                                        />
                                                      )
                                                    )}
                                                  </Box>
                                                </Grid>
                                              );
                                            })}
                                        </Grid>
                                      ) : null}
                                    </>
                                  )}
                                </Field>
                                {renderNested[question.id]?.render && (
                                  <Box mt={4}>
                                    {nestedQuestions
                                      .filter(
                                        (nested) =>
                                          nested.id ===
                                          renderNested[question.id]?.data
                                            ?.nestedQuestionId
                                      )
                                      .map((nested) => {
                                        console.log(nested, "nested")

                                        return (
                                          <Grid
                                            container
                                            spacing={3}
                                            key={nested.id}
                                          >
                                            {nested.options.map((option) => {

                                              if (option?.metadata?.type === "TEXTAREA") {
                                                return (
                                                  <Grid item width={'100%'}>
                                                    <textarea
                                                      name={`${question.id}_${option.id}`}
                                                      style={{ width: '100%', height: '100px' }}
                                                      value={
                                                        values[
                                                        `${question.id}_${option.id}`
                                                        ]
                                                      }
                                                      maxLength={200}
                                                      onChange={(event) =>
                                                        setFieldValue(
                                                          `${question.id}_${option.id}`,
                                                          event.target.value
                                                        )
                                                      }
                                                      placeholder={option.toolTip}
                                                    />
                                                  </Grid>
                                                )
                                              }

                                              return (
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={6}
                                                  key={option.id}
                                                >
                                                  {option.allowComment && (
                                                    <TextField
                                                      name={`${question.id}_${option.id}`}
                                                      value={
                                                        values[
                                                        `${question.id}_${option.id}`
                                                        ]
                                                      }
                                                      onChange={(event) =>
                                                        setFieldValue(
                                                          `${question.id}_${option.id}`,
                                                          event.target.value
                                                        )
                                                      }
                                                      variant="outlined"
                                                      fullWidth
                                                      size="small"
                                                      placeholder={option.toolTip}
                                                      sx={{ borderRadius: 6 }}
                                                    />
                                                  )}
                                                </Grid>
                                              );
                                            })}
                                          </Grid>
                                        )
                                      })}
                                  </Box>
                                )}
                              </Box>
                            )
                          )}

                          <Box textAlign="center" width="100%">
                            {currentSection.index !== 0 && (
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={handleBack}
                                sx={{
                                  marginTop: "16px",
                                  marginRight: 2,
                                  width: "140px",
                                  border: '0.5px solid #808080',
                                  backgroundColor: "RGBA(250,250,250,.30)", // Grey background color
                                  color: "white", // White text color
                                  "&:hover": {
                                    backgroundColor: "#696969", // Darker grey on hover
                                  },
                                }}
                              >
                                Snap Back
                              </Button>
                            )}
                            {currentSection.title !== "Confirm-it" && (
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleNext(values, validateSection)
                                }
                                sx={{
                                  marginTop: "16px",
                                  marginRight: 2, // Increased space to the right
                                  width: "140px", // Increased width
                                  backgroundColor: "#F7931D",
                                }}
                              >
                                Up Next
                              </Button>
                            )}
                            {currentSection.title === "Confirm-it" && (
                              <Fragment>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    marginTop: "16px",
                                    marginLeft: 1,
                                    width: "140px",
                                    backgroundColor: "#0171BC",
                                    "&:hover": {
                                      backgroundColor: "#e67e22",
                                    },
                                  }}
                                  disabled={!isChecked}
                                >
                                  Confirm
                                </Button>
                              </Fragment>
                            )}
                          </Box>
                        </Stack>
                      </Fragment>
                    )}
                  </Box>
                </Fragment>
              </Form>
            )}
            <DialogStandard
              open={open}
              handleClose={handleClose}
              title="Autonaut Alert!"
              message="Before you shift gears and zoom ahead, make sure you’ve responded to the question. Your mission is critical—don’t leave any details in the dust!."
              buttonText="GOT IT"
            />
          </Box>
        )}
      </Formik>
    </Fragment>
  );
};

export default MissionElevate;
