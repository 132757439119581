import { MobileStepper, Step, StepLabel, Stepper } from "@mui/material";
import { Check } from "lucide-react";
import { Fragment, useEffect, useRef, useState } from "react";
import getConfig from "../helpers/getConfig";
import img from '../images/CulturalFormStatic/arrow-img.png';
import imgBack from '../images/CulturalFormStatic/arrow-back.png';
export default function AppStepper({ sectionedQuestions, currentIndex }) {
  const uniqueSectionTitles = [
    "About", 'Candidate’s Detail', 'Spark Joy', 'Lead by Action', 'Raise Standards', 'Make It Happen', 'Reach for the Stars', 'Love What You Do', 'Put the Organization Above Self', 'Thank You',
  ];
  const location = window.location.href || "https://feedback-survey.qac24svc.dev/mission-elevate";
  const pathname = new URL(location).pathname;
  const hostEnv = getConfig()
  //Local States
  const [prevStep, setPrevStep] = useState(currentIndex);
  const prev = useRef(-1);
  const isBackwards = currentIndex < prev.current;
  useEffect(() => {
    prev.current = currentIndex;
  }, [currentIndex])
  const isMobile = window.innerWidth < 1024;
  const URL_TITLE_MAP = {
    "/": "Conflict of Interest (COI) - Declaration",
    "/it-asset": "IT Assets Insurance",
    "/mission-elevate": "Mission Elevate Show Your Enthusiasm"
  };
  const getActiveStep = () => {
    if (currentIndex === 0) {
      return 0;
    }
    return currentIndex;
  };
  const isLastStep = currentIndex === uniqueSectionTitles.length - 1;

  //console.log(currentIndex, "Prev-step");
  return (
    <Fragment>
      <div style={{
        fontSize: '20px',
        paddingTop: '20px',
        fontWeight: '500',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: "58px",
        color: 'white'
      }}>
        <div style={{ fontSize: '32px', fontWeight: '600' }}>
          {pathname === "/mission-elevate" ? (
            <>
              <span style={{ color: 'orange' }}>Mission Elevate</span>{' '}
              <span style={{ color: '#0358A9' }}>Show Your Enthusiasm</span>
            </>
          ) : (
            URL_TITLE_MAP[pathname]
          )}
        </div>
        {pathname === "/mission-elevate" && (
          <div>
            <img
              height={40}
              src={hostEnv.logo}
              alt="Logo"
            />
          </div>
        )}
      </div>
      {currentIndex > 0 && (
        <div style={{ width: "100%", fontSize: '20px', fontWeight: '500', textAlign: 'left' }}>
          {isMobile ? (
            <MobileStepper
              variant="progress"
              steps={uniqueSectionTitles.length}
              position="static"
              activeStep={getActiveStep()}
              sx={{
                ".MuiLinearProgress-root": {
                  width: '100%'
                }
              }}
            />
          ) : (
            <Stepper
              alternativeLabel
              activeStep={getActiveStep()}
              sx={{ width: "100%" }}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {uniqueSectionTitles.map((sectionTitle, index) => (
                <Step key={index}>
                  <StepLabel
                    sx={{
                      color: "#FFF", // Keeps the label white
                      '& .MuiStepLabel-label': {
                        color: '#FFF !important', // Ensures label text color stays white
                      },
                      '& .MuiStepLabel-active': {
                        color: '#FFF !important', // Keeps the active step's label white
                      },

                    }}
                    StepIconComponent={(props) => (
                      <CustomIcon
                        style={{ backgroundColor: 'white' }}
                        {...props}
                        index={index}
                        currentIndex={currentIndex}
                        isBackwards={isBackwards}
                      />

                    )}

                  >
                    {sectionTitle}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
        </div>
      )
      }
      {
        isLastStep && (
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: '#FFFFFF',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: '500',
            borderRadius: '8px',
            marginTop: "200px",
          }}>
            Your response has been transmitted to our cosmic databanks. You're all set, no further action needed from your space station!
          </div>
        )
      }
    </Fragment >
  );
}
function CustomIcon({ active, completed, currentIndex, isBackwards }) {
  console.log(currentIndex, active, isBackwards);
  const getImageSrc = () => {
    if (active && isBackwards) {
      return imgBack;
    } else if (active) {
      return img;
    } else {
      return '';
    }
  };
  return (
    <div
      style={{
        background: completed ? "#0171BC" : "#FFFFFF",
        border: !completed && "2px solid #F7931D",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 20,
        height: 20,
        borderRadius: "50%",
        color: "#fff",
        fontWeight: "bold",
        fontSize: "12px",
        position: "relative",
        transition: "background-color 5.5s ease, border-color 5.5s ease",
      }}
    >
      {completed ? (
        <Check size={18} />
      ) : active ? (
        <img
          src={getImageSrc()}
          style={{
            position: "absolute",
            transition: "left 5.5s ease, transform 5.5s ease",
            transform: `rotate(${isBackwards ? "-0deg" : "0deg"})`,
          }}
        />
      ) : null}
    </div>

  );
}
