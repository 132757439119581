import { Stack } from "@mui/material";
import { Fragment } from "react";
import ItAssetFeedbackForm from "../Components/ITAssetInsurance/ITAsset";
import { Helmet } from "react-helmet";
import getConfig from "../helpers/getConfig";
import CulturalForm from "../Components/CulturalForm/CulturalForm";

export default function ItAssetFormRoute() {
  const backgroundImg = 'it-asset-bg-fallback';

  const hostEnv = getConfig()

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>IT Asset Insurance</title>
        <link rel="icon" href={hostEnv.favicon} />
      </Helmet>
      <Stack className={backgroundImg} id='base-container' sx={{ height: "100vh", position: 'relative' }}>
        <ItAssetFeedbackForm />
      </Stack>
    </Fragment>
  )
} 