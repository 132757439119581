import './loginFormMobile.css';
import loginLeftImg from '../login-mobile-img.svg'
import LoginLeftCariot from '../login-left-cariot.svg';
import GoogleCTA from '../GoogleCTA/GoogleCTA';
import { LOGIN_CONFIG } from '../../../helpers/constants';
import getConfig from '../../../helpers/getConfig';

export default function LoginFormMobile() {
  const hostEnv = getConfig()
  const pathname = localStorage.getItem("pathname") || "/";

  let config;

  if (pathname === "/mission-elevate") {
    config = LOGIN_CONFIG.missionElevate
  } else if (pathname === "/it-asset") {
    config = LOGIN_CONFIG.itAsset
  } else if (pathname === "/cultural-form") {
    config = LOGIN_CONFIG.culturalForm
  }
  else if (pathname === "/tech-session-form") {
    config = LOGIN_CONFIG.dynamicForm
  }
  else {
    config = LOGIN_CONFIG.coi
  }

  const leftAutonautImage = hostEnv.entity === "CARS24" ? loginLeftImg : LoginLeftCariot;

  return (
    <div className='loginFormMobile'>
      <div className='loginFormMobileBanner'>
        <img src={leftAutonautImage} alt='mobile-img' />
      </div>
      <div style={{ marginTop: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h4 className='loginMobileTitle' style={{ color: '#F7931D' }}>{config.title}</h4>
        <h4 className='loginMobileTitle'>{config?.title2}</h4>
        <h4 className='loginMobileTitle'>{config?.title3}</h4>
        <h4 className='loginMobileTitle'>{config?.title4}</h4>
      </div>
      <div style={{ marginTop: '8px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <p style={{ textAlign: 'center', fontSize: '18px', color: '#0557AB' }}>{config?.para1}</p>
      </div>
      <div style={{ marginTop: '32px' }}>
        <GoogleCTA />
      </div>
    </div>
  )
}