// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/Back.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginForm {
  height: 100vh;
  background: #000000;
}

.loginFormContainer {
  height: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.loginFormContainerLeft {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 0px 48px;
}

.loginFormTitle {
  color: white;
  font-weight: 900;
  font-size: 40px;
  line-height: 60px;
}

.loginFormDescription {
  margin-top: 16px;
  font-size: 18px;
}

.loginFormContainerRight {
  height: 100%;
  /* background-color: white; */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  border-left: 1px grey solid;
  position: relative;
  padding: 0px 48px;
}

.loginFormContainerRightImg {
  position: fixed;
  top: 24px;
  right: 24px;
}`, "",{"version":3,"sources":["webpack://./src/Components/login/LoginForm/loginForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,yDAAiD;EACjD,2BAA2B;EAC3B,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,SAAS;EACT,WAAW;AACb","sourcesContent":[".loginForm {\n  height: 100vh;\n  background: #000000;\n}\n\n.loginFormContainer {\n  height: 100%;\n  display: grid;\n  grid-template-columns: 2fr 3fr;\n}\n\n.loginFormContainerLeft {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  color: white;\n  padding: 0px 48px;\n}\n\n.loginFormTitle {\n  color: white;\n  font-weight: 900;\n  font-size: 40px;\n  line-height: 60px;\n}\n\n.loginFormDescription {\n  margin-top: 16px;\n  font-size: 18px;\n}\n\n.loginFormContainerRight {\n  height: 100%;\n  /* background-color: white; */\n  background-image: url('../../../images/Back.png');\n  border-left: 1px grey solid;\n  position: relative;\n  padding: 0px 48px;\n}\n\n.loginFormContainerRightImg {\n  position: fixed;\n  top: 24px;\n  right: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
