import { jwtDecode } from "jwt-decode";

export const isTokenValid = (token) => {
    if (!token) {
      return false;
    }
    
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      console.log(decodedToken.exp, "Token expiry",  currentTime, "Current Time");
      if (decodedToken.exp > currentTime) {
        return true;
      } else {
        localStorage.removeItem("accessToken");
        return false;
      }
    } catch (error) {
      console.error("Invalid token:", error);
      localStorage.removeItem("accessToken");
      return false;
    }
  };