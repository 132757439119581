// Working validations

import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Typography,
  Box,
  Stack,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Grid,
  Checkbox,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import "./Feedback-it-insurance.css";
import getConfig from "../../helpers/getConfig";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { Snackbar, Alert } from "@mui/material";
import { setCookie } from "../../helpers/setCookie";
import { v4 as uuidv4 } from "uuid";
import tableImage from "../../images/ITAssetStatic/it-asset-group-img.svg";
import tableImageMobile from "../../images/ITAssetStatic/it-asset-group-mobile-img.svg";
import Lottie from "react-lottie";
import * as animation from "../../images/rocket-animation.json";
import { surveyEndpointMap } from "../../helpers/constants";
import AlertSnackbar from "../core/AlertSnackbar/AlertSnackbar";
import DialogStandard from "../core/DialogStandard/DialogStandard";

const tooltipToKeyMap = {
  "Employee Name": "Name",
  "Employee ID": "Employee ID",
  Designation: "Designation",
  Band: "Band",
  Department: "Department",
  "Date of Joining": "Date of Joining",
};

const ItAssetFeedbackForm = () => {
  const [formQuestions, setFormQuestions] = useState([]);
  const [feedbackIdentifier, setFeedbackIdentifier] = useState(1000);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState({
    index: 0,
    title: "Stellar Data",
  });
  const [sectionedQuestions, setSectionedQuestions] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [optionQuestionsMap, setOptionQuestionsMap] = useState({});
  const [optionIds, setOptionIds] = useState({});
  const [optionWeights, setOptionWeights] = useState({});
  const urlParams = new URLSearchParams(window.location.search);
  const [details, setDetails] = useState({
    "Employee Name": "",
    "Employee ID": "",
    Designation: "",
    Band: "",
    Department: "",
    "Date of Joining": "",
  });
  const [toast, setToast] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [isUserDetailsFetched, setIsUserDetailsFetched] = useState(false);
  const [hasUserAlreadySubmitted, setHasUserAlreadySubmitted] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState("");

  const backgroundImages = [
    "it-asset-bg-1",
    "it-asset-bg-2",
    "it-asset-bg-3",
    "it-asset-bg-4",
  ];

  const reqId = uuidv4();

  const hostEnv = getConfig();

  const surveyType = "IT Asset";
  const entity = hostEnv.entity;

  const endpoint = surveyEndpointMap[surveyType]?.[entity];

  const preloadImages = (images) => {
    images.forEach((image) => {
      const img = new Image();
      img.src = `../images/ITAssetStatic/it-asset-mobile-${image}.jpg`;
    });
  };

  useEffect(() => {
    preloadImages(backgroundImages);
  }, []);

  useEffect(() => {
    const baseContainer = document.getElementById("base-container");

    const existingClass = Array.from(baseContainer.classList).find(
      (className) => className.startsWith("it-asset-bg-")
    );

    if (existingClass) {
      baseContainer.classList.remove(existingClass);
    }

    if (hasUserAlreadySubmitted) {
      baseContainer.classList.add(`it-asset-bg-${backgroundImages.length - 1}`);
    } else {
      baseContainer.classList.add(`it-asset-bg-${currentSection?.index}`);
    }

  }, [currentSection?.index, hasUserAlreadySubmitted]);

  useEffect(() => {
    axios
      .get(`${hostEnv.insightBaseUrl}/config/rating/hrms/${endpoint}`)
      .then((response) => {
        const data = response.data?.data;

        const questions = Object.values(data?.initialQuestions);
        const optionQuestionsMap = data?.optionQuestionsMap;
        const sortedQuestions = questions.sort(
          (a, b) => a.sequence - b.sequence
        );

        // Organize questions by section
        const sections = {};
        const initialValues = {};
        const optionIds = {};
        const optionWeights = {};

        sortedQuestions.forEach((question) => {
          const sectionTitle = question.metadata?.section?.title;
          if (!sections[sectionTitle]) {
            sections[sectionTitle] = [];
          }
          sections[sectionTitle].push(question);

          initialValues[question.id] = "";
          optionIds[question.id] = [];
          optionWeights[question.id] = {};
          question.options.forEach((option) => {
            optionIds[question.id].push(option.id);
            optionWeights[question.id][option.id] = option.weightage;
          });
        });

        setFormQuestions(sortedQuestions);
        setSectionedQuestions(sections);
        setInitialValues(initialValues);
        setOptionIds(optionIds);
        setOptionWeights(optionWeights);
        setOptionQuestionsMap(optionQuestionsMap);
        setFeedbackIdentifier(data?.ssId);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching form questions:", error);
        setLoading(false);
      });
  }, [hostEnv.insightBaseUrl]);

  const sectionTitles = Object.keys(sectionedQuestions);

  const handleSubmit = async (values) => {
    const sectionErrors = validateSection(currentSection.title, values);
    if (Object.keys(sectionErrors).length === 0) {
      let answerList = [];
      console.log("FINAL", values);

      console.log(values, "valuessss");

      // Loop through each key in values
      Object.keys(values).forEach((key) => {
        let questionId, optionId, comment;

        // Check if the key contains an underscore, indicating a questionId_optionId format
        if (key.includes("_")) {
          [questionId, optionId] = key.split("_");
          comment = values[key];
        } else {
          questionId = key;
          optionId = values[key];
          comment = "";
        }

        // Get the selected option weightage
        const selectedOptionWeightage = optionWeights[questionId][optionId];

        // Push the entry into the answerList
        answerList.push({
          optionId: optionId,
          weightage: "",
          comment: comment,
          metadata: {},
          questionId: questionId,
        });
      });
      answerList.forEach((answer) => {
        if (answer.questionId === "61" && answer.optionId === "299") {
          answer.weightage = 1;
        }
        if (answer.questionId === "62" && answer.optionId === "301") {
          answer.weightage = 1;
        }
      });

      console.log(answerList, "Answer List");
      const userDetails = JSON.parse(localStorage.getItem("user-details"));

      const feedbackResponse = {
        ssId: `${feedbackIdentifier}`,
        identifierId: `${reqId}`,
        identifierType: "IT_ASSET_INSURANCE_FEEDBACK",
        feedbackGivenFor: uuidv4(),
        feedbackGivenForEntityType: `${hostEnv.entity}`,
        answer: answerList,
        feedbackGivenByMetadata: {
          externalId: "sdfdsf-34234-sdfsdf-234234",
        },
        feedbackGivenBy: `${userDetails?.Email}`,
        feedbackGivenByEntityType: "EMPLOYEE",
      };

      const headers = {
        "x-channel-name": "hrms_web",
        token: "Bearer token",
      };

      try {
        await axios.post(
          `${hostEnv.insightBaseUrl}/feedback`,
          feedbackResponse,
          {
            headers: headers,
          }
        );
        await axios.post(
          "https://hrms-dbms-prod-6lptus6tza-el.a.run.app/ems/set_redis",
          {
            survey_id: feedbackIdentifier,
            emp_id: details?.["Employee ID"],
          }
        );
        setSubmitted(true);
        setToast({
          open: true,
          message: "Feedback submitted successfully!",
          severity: "success",
        });
        const userEmail = JSON.parse(
          localStorage.getItem("user-details")
        )?.Email;
        setCookie("coi-employee-feedback", userEmail, 90);
        setHasUserAlreadySubmitted(true);
      } catch (error) {
        console.error("Error:", error);
        setToast({
          open: true,
          message: "Failed to submit feedback.",
          severity: "error",
        });
      }
    } else {
      setOpen(true); // Show validation dialog if there are errors
    }
  };

  const handleNext = (values, validateForm) => {
    const sectionErrors = validateSection(currentSection.title, values);
    if (Object.keys(sectionErrors).length === 0) {
      if (currentSection.index < sectionTitles.length - 1) {
        setCurrentSection((prev) => {
          const newIndex = prev.index + 1;
          // TODO Update background image
          setBackgroundImage(backgroundImages[newIndex - 1]);
          return {
            index: newIndex,
            title: sectionTitles[newIndex],
          };
        });
      }
    } else {
      setOpen(true); // Show validation dialog if there are errors
    }
  };

  const validateSection = (sectionTitle, values) => {
    const sectionQuestions = sectionedQuestions[sectionTitle] || [];
    let errors = {};

    sectionQuestions.forEach((question) => {
      // console.log(values, "HEDE")
      // Check if the main question is answered
      const EXCEPTED_SECTIONS = [
        "Stellar Data",
        "Conflict Of Interest",
        "Declaration",
      ];
      console.log(question?.metadata, "question");
      const isValidationRequired = question?.metadata?.isValidationRequired;
      console.log(isValidationRequired, "isValidationRequired");

      if (!EXCEPTED_SECTIONS.includes(sectionTitle) && !values[question.id]) {
        errors[question.id] = "This field is required";
      }

      if (sectionTitle === "Stellar Data") {
        // Check if any combined `question.id_option.id` value is empty
        question.options.forEach((option) => {
          const fieldName = `${question.id}_${option.id}`;
          if (!values[fieldName]) {
            errors[fieldName] = "This field is required";
          }
        });
      }
    });

    return errors;
  };

  const handleChange = (questionId, value, values, setFieldValue) => {
    try {
      // Update the selected value
      setFieldValue(questionId, value);

      // Get the value associated with the key from optionQuestionsMap
      const valueToCheck = optionQuestionsMap[value]
        ? optionQuestionsMap[value][0]
        : null;

      // Remove any questionId_optionId keys from values if the option is not present in optionQuestionsMap
      Object.keys(values).forEach((key) => {
        if (key.startsWith(`${questionId}_`)) {
          const optionId = key.split("_")[1];
          // console.log(optionId, "optionss")
          if (!optionQuestionsMap[optionId]) {
            setFieldValue(key, undefined); // Removes the key from Formik values
          }
        }
      });
    } catch (error) {
      console.error("Error in handleChange:", error.message);
    }
  };

  const handleBack = () => {
    if (currentSection.index > 0) {
      setCurrentSection((prev) => {
        const newIndex = prev.index - 1;
        // TODO Update background image
        setBackgroundImage(backgroundImages[newIndex - 1]); // Access the image from the array
        return {
          index: newIndex,
          title: sectionTitles[newIndex],
        };
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showFormImage = window.innerWidth >= 1440;

  const getUserInformation = () => {
    const sessionToken = localStorage.getItem("accessToken");

    if (sessionToken) {
      const decoded = jwtDecode(sessionToken);
      const email = decoded?.email;

      axios
        .get(`${hostEnv.employeeBaseUrl}/fetch_employee`, {
          params: {
            email: email,
            survey_id: feedbackIdentifier,
          },
        })
        .then((response) => {
          const data = response?.data?.data;
          const message = response?.data?.message;
          if (data) {

            let name = "";

            if (data?.first_name && data?.last_name) {
              name = `${data?.first_name} ${data?.last_name}`;
            } else if (data?.first_name) {
              name = `${data?.first_name}`;
            }

            // console.log(data, 'data');
            const payload = {
              Name: name,
              Department: data?.department_name,
              Band: data?.band,
              "Date of Joining": dayjs(data?.date_of_joining).format(
                "DD/MM/YYYY"
              ),
              Designation: data?.designation,
              "Employee ID": data?.employee_id,
              Email: data?.company_email_id,
            };

            setDetails((prev) => ({
              ...prev,
              Name: name,
              Department: data?.department_name,
              Band: data?.band,
              "Date of Joining": dayjs(data?.date_of_joining).format(
                "DD/MM/YYYY"
              ),
              Designation: data?.designation,
              "Employee ID": data?.employee_id,
              Date: dayjs().format("DD/MM/YYYY"),
            }));
            localStorage.setItem("user-details", JSON.stringify(payload));
            setIsUserDetailsFetched(true);
          } else if (message) {
            setHasUserAlreadySubmitted(true);
            setIsUserDetailsFetched(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsUserDetailsFetched(true);
        });
    }
  };

  useEffect(() => {
    if (feedbackIdentifier) {
      getUserInformation();
    }
  }, [feedbackIdentifier]);

  const title = currentSection?.title;
  const sectionData = sectionedQuestions[title] && sectionedQuestions[title][0];
  const metadata = sectionData?.metadata?.section;

  const metaTitle = metadata?.title;
  const metaDesc = metadata?.description;
  const metaDesc2 = metadata?.description2;
  const metaDesc3 = metadata?.description3;
  const metaList = metadata?.details;
  const staticContent = metadata?.staticContent;
  const showITAssetImage = metadata?.showITAssetImage;
  const key = sectionData?.metadata?.showTitle;
  const footerText = metadata?.footer;

  const isAlreadySubmitted = hasUserAlreadySubmitted;

  const isMobile = window.innerWidth < 1024;

  return (
    <Fragment>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Box className="form-wrapper-it" sx={{ paddingLeft: "16px", paddingRight: "16px" }}>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Form className="form-card-it-asset gradient-border">
                {isAlreadySubmitted ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "24px",
                    }}
                  >
                    <Lottie
                      options={{
                        loop: false,
                        autoplay: true,
                        animationData: animation,
                      }}
                      height={isMobile ? 300 : 400}
                      width={isMobile ? 300 : 400}
                    />
                    <p style={{ color: "#FFFFFF", padding: "16px", fontSize: window.innerWidth < 1024 ? "16px" : "20px" }}>
                      Your reply has been beamed into our cosmic databanks. No need for any more moves from your space station, you're all set! 🚀
                    </p>
                  </div>
                ) : (
                  // <AlreadySubmitted />
                  <Fragment>
                    <AlertSnackbar
                      open={toast.open}
                      message={toast.message}
                      severity={toast.severity}
                      autoHideDuration={6000}
                      onClose={() => setToast({ ...toast, open: false })}
                    />
                    <Box p={{ xs: 2, sm: 6 }}>
                      <Typography mb={2} textAlign={"center"} color={"#FFFFFF"}>
                        {true && (
                          <strong
                            style={{
                              color: "#F7931E",
                              fontSize: "24px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                              //lineHeight: '18.34px',
                              textAlign: "center",
                              marginTop: "0",
                            }}
                          >
                            {metaTitle}
                          </strong>
                        )}
                        {metaDesc}
                      </Typography>
                      {(metaDesc2 || metaDesc3) && (
                        <Typography
                          mb={2}
                          textAlign={"center"}
                          color={"#FFFFFF"}
                        >
                          {metaDesc2 && (
                            <>
                              {metaDesc2}
                              <br />
                              <br />
                            </>
                          )}
                          {metaDesc3}
                        </Typography>
                      )}
                      <Box pl={2}>
                        <ul
                          style={{
                            textAlign: "center",
                            marginBottom: "40px",
                            color: "#FFFFFF",
                          }}
                        >
                          {metaList?.map((item, index) => (
                            <li
                              key={index}
                              style={{
                                marginBottom: "8px",
                                fontSize: "14px",
                                color: "#FFFFFF",
                                paddingLeft: "16px",
                              }}
                            >
                              {item?.key}: {item?.value}
                            </li>
                          ))}
                        </ul>
                      </Box>
                      <Box>
                        {staticContent?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                textAlign: "center",
                                marginBottom: "16px",
                              }}
                            >
                              <Typography
                                variant="h6"
                                style={{
                                  color: "#FFA500",
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  lineHeight: "19.36px",
                                  textAlign: "center",
                                }}
                                mt={2}
                                mb={2}
                                textAlign={"center"}
                              >
                                {item?.heading}
                              </Typography>

                              <Typography
                                mb={2}
                                textAlign={"center"}
                                color={"#FFFFFF"}
                                fontSize={"15.67px"} // Updated font size
                                fontWeight={500} // Updated font weight
                                lineHeight={"18.97px"} // Updated line height
                                fontFamily="Inter" // Added font family
                              >
                                {item?.description && (
                                  <>
                                    {item.description}
                                    <br />
                                    <br />
                                  </>
                                )}
                                {item?.description1 && (
                                  <>
                                    {item.description1}
                                    <br />
                                    <br />
                                  </>
                                )}
                                {item?.description2 && (
                                  <>
                                    {item.description2}
                                    <br />
                                    <br />
                                  </>
                                )}
                                {item?.description3 && (
                                  <>
                                    {item.description3}
                                    <br />
                                    <br />
                                  </>
                                )}
                                {item?.description4 && (
                                  <>
                                    {item.description4}
                                    <br />
                                    <br />
                                  </>
                                )}
                                {item?.description5 && (
                                  <>
                                    {item.description5}
                                    <br />
                                    <br />
                                  </>
                                )}
                                {item?.description6}
                              </Typography>
                            </div>
                          );
                        })}
                      </Box>

                      {submitted ? (
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "24px",
                          }}
                        >
                          <Lottie
                            options={{
                              loop: false,
                              autoplay: true,
                              animationData: animation,
                            }}
                            height={400}
                            width={400}
                          />
                          Your choice has been beamed up to our cosmic HQ.
                          You're all set! 🚀
                        </div>
                      ) : (
                        // <Typography
                        //   variant="h5"
                        //   fontFamily="Roboto"
                        //   fontWeight={500}
                        // >
                        //   Response Submitted
                        // </Typography>
                        <Stack spacing={4}>
                          {sectionedQuestions[currentSection.title]?.map(
                            (question) => (
                              <Box
                                sx={{ color: "#FFFFFF" }}
                                className="question-box"
                                key={question.id}
                              >
                                <Typography
                                  fontFamily="Roboto"
                                  fontWeight={500}
                                  mb={1}
                                  textAlign={"center"}
                                >
                                  {question?.metadata?.section?.showName ||
                                    question?.metadata?.showName ? (
                                    <Fragment>{question.name}</Fragment>
                                  ) : null}
                                </Typography>
                                {showITAssetImage && (
                                  <Box
                                    sx={{
                                      margin: "32px 0",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={
                                        window.innerWidth < 720
                                          ? tableImageMobile
                                          : tableImage
                                      }
                                      style={{ marginRight: "16px" }}
                                      alt="table-image"
                                    />
                                  </Box>
                                )}

                                <Field name={question.id}>
                                  {({ field }) => (
                                    <>
                                      {!question?.metadata?.showOptions &&
                                        question?.metadata?.showOptions !==
                                        false &&
                                        question.type === "SINGLE_SELECTION" ? (
                                        <RadioGroup
                                          {...field}
                                          name={question.id}
                                          value={values[question.id]}
                                          onChange={(event) => {
                                            setIsChecked(true);
                                            handleChange(
                                              question.id,
                                              event.target.value,
                                              values,
                                              setFieldValue
                                            );
                                          }}
                                        >
                                          {question.options
                                            .sort(
                                              (a, b) => a.sequence - b.sequence
                                            )
                                            .map((option) => {
                                              return (
                                                <FormControlLabel
                                                  key={option.id}
                                                  value={option.id}
                                                  control={<Radio />}
                                                  label={
                                                    (option?.toolTip || "") +
                                                    " " +
                                                    (option?.metadata
                                                      ?.description || "")
                                                  }
                                                />
                                              );
                                            })}
                                        </RadioGroup>
                                      ) : !question?.metadata?.showOptions &&
                                        question?.metadata?.showOptions !==
                                        false &&
                                        question.type === "TEXT" ? (
                                        <Grid container spacing={2}>
                                          {question.options
                                            .sort(
                                              (a, b) => a.sequence - b.sequence
                                            )
                                            .map((option) => {
                                              const tooltip = option?.toolTip;
                                              const userDetailValue =
                                                details[tooltip];
                                              const optionType =
                                                option?.metadata?.type;
                                              const showOptions =
                                                question?.metadata
                                                  ?.showOptions !== false;
                                              const isDeclarationSection =
                                                title === "Declaration";

                                              const isPresent = Object.keys(
                                                values
                                              ).find(
                                                (item) =>
                                                  item ===
                                                  `${question.id}_${option.id}`
                                              );

                                              if (
                                                !isPresent &&
                                                !isDeclarationSection &&
                                                isUserDetailsFetched
                                              ) {
                                                if (userDetailValue) {
                                                  setFieldValue(
                                                    `${question.id}_${option.id}`,
                                                    userDetailValue
                                                  );
                                                }
                                              }

                                              return (
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={6}
                                                  key={option.id}
                                                >
                                                  <Box mb={2}>
                                                    <Typography
                                                      fontFamily="Roboto"
                                                      fontWeight={500}
                                                      mb={1}
                                                      color="white"
                                                    >
                                                      {optionType !==
                                                        "CHECKBOX" &&
                                                        option?.toolTip}{" "}
                                                      {/* <span
                                                        style={{ color: "red" }}
                                                      >
                                                        {optionType !==
                                                          "CHECKBOX" && "*"}
                                                      </span> */}
                                                    </Typography>
                                                    {optionType ===
                                                      "CHECKBOX" ? (
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={
                                                              values[
                                                              `${question.id}_${option.id}`
                                                              ] || false
                                                            }
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              setFieldValue(
                                                                `${question.id}_${option.id}`,
                                                                event.target
                                                                  .checked
                                                              );
                                                              setIsChecked(
                                                                event.target
                                                                  .checked
                                                              );
                                                            }}
                                                          />
                                                        }
                                                        label={option.toolTip}
                                                      />
                                                    ) : (
                                                      option.allowComment && (
                                                        <TextField
                                                          {...field}
                                                          disabled={
                                                            userDetailValue
                                                          }
                                                          className="text-field"
                                                          name={`${question.id}_${option.id}`}
                                                          value={
                                                            userDetailValue ||
                                                            values[
                                                            `${question.id}_${option.id}`
                                                            ]
                                                          }
                                                          onChange={(event) =>
                                                            setFieldValue(
                                                              `${question.id}_${option.id}`,
                                                              event.target.value
                                                            )
                                                          }
                                                          variant="outlined"
                                                          fullWidth
                                                          size="small"
                                                          placeholder={
                                                            option.toolTip
                                                          }
                                                          sx={{
                                                            input: {
                                                              color: "white",
                                                            },
                                                          }}
                                                        />
                                                      )
                                                    )}
                                                  </Box>
                                                </Grid>
                                              );
                                            })}
                                        </Grid>
                                      ) : null}
                                    </>
                                  )}
                                </Field>
                              </Box>
                            )
                          )}

                          <Typography
                            fontFamily="Inter"
                            fontSize="14px"
                            fontStyle="italic"
                            fontWeight={500}
                            lineHeight="24.82px"
                            textAlign="center"
                            color={"white"}
                          >
                            {footerText}
                          </Typography>

                          <Box textAlign="center" width="100%">
                            {currentSection.index !== 0 && (
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={handleBack}
                                sx={{
                                  marginTop: "16px",
                                  marginRight: 2,
                                  width: "140px",
                                  backgroundColor: "#808080", // Grey background color
                                  color: "white", // White text color
                                  borderRadius:"8.89px",
                                  border:'none',
                                  "&:hover": {
                                    backgroundColor: "#696969", // Darker grey on hover
                                  },
                                }}
                              >
                                U-Turn
                              </Button>
                            )}
                            {currentSection.title !== "Declaration" && (
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  handleNext(values, validateSection)
                                }
                                sx={{
                                  marginTop: "16px",
                                  marginRight: 2, // Increased space to the right
                                  width: "140px", // Increased width
                                  borderRadius:"8.89px",
                                }}
                              >
                                Game On
                              </Button>
                            )}
                            {currentSection.title === "Declaration" && (
                              <Fragment>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    marginTop: "16px",
                                    marginRight: 2,
                                    width: '150px',
                                    backgroundColor: "rgba(4, 137, 226, 1)",
                                    border:'none',
                                    borderRadius:"8.89px"
                                    // "&:hover": {
                                    //   backgroundColor: "#e67e22",
                                    // },
                                  }}
                                  disabled={!isChecked}
                                >
                                  Ignite
                                </Button>
                              </Fragment>
                            )}
                          </Box>
                        </Stack>
                      )}
                    </Box>
                  </Fragment>
                )}
              </Form>
            )}
            <DialogStandard
              open={open}
              handleClose={handleClose}
              title="Autonaut Alert!"
              message="Before you shift gears and zoom ahead, make sure you’ve responded to the question. Your mission is critical—don’t leave any details in the dust!."
              buttonText="GOT IT"
            />
          </Box>
        )}
      </Formik>
    </Fragment>
  );
};

export default ItAssetFeedbackForm;
