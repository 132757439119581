import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#f47514', // This would be your primary color
      light: '#f8a86b',
      dark: '#da6407',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fcc79f', // This would be your secondary color
      light: '#ffe3ce',
      dark: '#c35802',
      contrastText: '#000',
    },
    background: {
      default: '#fff3e2', // Lightest color in the palette for background
      paper: '#ffe3ce', // Slightly darker for paper backgrounds
    },
    text: {
      primary: '#000', // Dark text for contrast
      secondary: '#aa4900', // Secondary text color
    },
    error: {
      main: '#f47e24', // You could use this as an error color if needed
    },
    warning: {
      main: '#f58e3f', // Could be used for warning messages
    },
    info: {
      main: '#fcc79f', // Could be used for informational messages
    },
    success: {
      main: '#f8a86b', // Could be used for success messages
    },
    grey: {
      100: '#535353', // Light grey
      300: '#535353', // Medium grey
      500: '#535353', // Darker grey
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    button: {
      textTransform: 'none'
    }
  },
});

export default theme;