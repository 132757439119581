const appConfig = {
  qa: {
    env: "qa",
    redirectUri: "https://feedback-survey.qac24svc.dev/login",
    clientId: "565623820835-c8ifgjo1sfi6klr1mjc41jcdjpgq3cum.apps.googleusercontent.com",
    loginBaseUrl: "https://cosmonaut.qac24svc.dev/api/v1/sso/login",
    insightBaseUrl: "https://insight360.qac24svc.dev/api/v2",
    employeeBaseUrl: 'https://hrms.c24.tech/ems',
    entity: "CARS24",
    logo: "https://fastly-production.24c.in/cars24/seo/static/1_20230830_1693395013.png",
    favicon: '/favicon.ico',
    headers: {
      'client_id': 'hrms',
      'client_secret': 'vl5d0JrOEdA41zu8S93j9N0omQYNkKZ1',
      'x-channel-name': 'hrms-app',
      'Content-Type': 'application/json'
    }
  },
  local: {
    env: "local",
    redirectUri: "http://localhost:8080/login",
    clientId: "565623820835-c8ifgjo1sfi6klr1mjc41jcdjpgq3cum.apps.googleusercontent.com",
    loginBaseUrl: "https://cosmonaut.qac24svc.dev/api/v1/sso/login",
    insightBaseUrl: "https://insight360.qac24svc.dev/api/v2",
    employeeBaseUrl: 'https://hrms.c24.tech/ems',
    entity: "CARS24",
    logo: "https://fastly-production.24c.in/cars24/seo/static/1_20230830_1693395013.png",
    favicon: '/favicon.ico',
    headers: {
      'client_id': 'hrms-local',
      'client_secret': 'UmVamAqt59Thg2427cw1o4wGLpyUOkTY',
      'x-channel-name': 'hrms-app',
      'Content-Type': 'application/json'
    }
  },
  prod: {
    env: "production",
    redirectUri: "https://employee-feedback.cars24.team/login",
    clientId: "565623820835-c8ifgjo1sfi6klr1mjc41jcdjpgq3cum.apps.googleusercontent.com",
    loginBaseUrl: "https://cosmonaut.c24.tech/api/v1/sso/login",
    insightBaseUrl: "https://insight360.c24.tech/api/v2",
    employeeBaseUrl: 'https://hrms.c24.tech/ems',
    entity: "CARS24",
    logo: "https://fastly-production.24c.in/cars24/seo/static/1_20230830_1693395013.png",
    favicon: '/favicon.ico',
    headers: {
      'client_id': 'hrms',
      'client_secret': 'H5lZJefduGyG5rtATfVCfTiymsanXPNk',
      'x-channel-name': 'hrms-app',
      'Content-Type': 'application/json',
    }
  },
  qa_cariot: {
    env: "qa_cariot",
    redirectUri: "https://feedback-survey-cariot.qac24svc.dev/login",
    clientId: "565623820835-c8ifgjo1sfi6klr1mjc41jcdjpgq3cum.apps.googleusercontent.com",
    loginBaseUrl: "https://cosmonaut.qac24svc.dev/api/v1/sso/login",
    insightBaseUrl: "https://insight360.qac24svc.dev/api/v2",
    employeeBaseUrl: 'https://capl-dbms-541034858590.asia-south1.run.app',
    entity: "CARIOT",
    logo: "https://cariotauto.com/wp-content/uploads/2023/09/New-Project-4.png",
    favicon: '/favicon-cariot.ico',
    headers: {
      'client_id': 'hrms-cariot-coi',
      'client_secret': 'b2jmC8Gd1vuLWgJz6KdNn8ff9qyw1Ejw',
      'x-channel-name': 'hrms-app',
      'Content-Type': 'application/json',
    }
  },
  prod_cariot: {
    env: "production_cariot",
    redirectUri: "https://employee-feedback.cariotauto.team/login",
    clientId: "565623820835-c8ifgjo1sfi6klr1mjc41jcdjpgq3cum.apps.googleusercontent.com",
    loginBaseUrl: "https://cosmonaut.c24.tech/api/v1/sso/login",
    insightBaseUrl: "https://insight360.c24.tech/api/v2",
    employeeBaseUrl: 'https://capl-dbms-541034858590.asia-south1.run.app',
    entity: "CARIOT",
    logo: "https://cariotauto.com/wp-content/uploads/2023/09/New-Project-4.png",
    favicon: '/favicon-cariot.ico',
    headers: {
      'client_id': 'hrms-cariot-coi',
      'client_secret': '6jSLxT2oyBwgcKSVbqM1rs1yYR5mazPV',
      'x-channel-name': 'hrms-app',
      'Content-Type': 'application/json',
    }
  }
};

const getConfig = () => {
  const env = process.env.REACT_APP_HOST_ENV || 'production';

  if (env.toLowerCase() === "production") {
    return appConfig.prod;
  } else if (env.toLowerCase() === "qa") {
    return appConfig.qa;
  } else if (env.toLowerCase() === "production_cariot") {
    return appConfig.prod_cariot;
  } else if (env.toLowerCase() === "qa_cariot") {
    return appConfig.qa_cariot;
  } else if (env.toLocaleLowerCase() === "local") {
    return appConfig.local
  }
};

export default getConfig;
