// Working validations

import React, { useState, useEffect, Fragment } from "react";
import {
  Button,
  Typography,
  Box,
  Stack,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Grid,
  Divider,
  Checkbox,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import "../FeedbackForm.css";
import getConfig from "../../../helpers/getConfig";
import AppStepper from "../../AppStepper";
import dayjs from "dayjs";
import { Snackbar, Alert } from '@mui/material';
import { setCookie } from "../../../helpers/setCookie";
import { getCookie } from "../../../helpers/getCookie";
import { surveyEndpointMap } from "../../../helpers/constants";
import AlertSnackbar from "../../core/AlertSnackbar/AlertSnackbar";


const COIFormCandidate = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const applicantName = urlParams.get("name");

  const [formQuestions, setFormQuestions] = useState([]);
  const [feedbackIdentifier, setFeedbackIdentifier] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState({
    index: 0,
    title: "Candidate Details",
  });
  const [sectionedQuestions, setSectionedQuestions] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [nestedQuestions, setNestedQuestions] = useState({});
  const [optionQuestionsMap, setOptionQuestionsMap] = useState({});
  const [optionIds, setOptionIds] = useState({});
  const [optionWeights, setOptionWeights] = useState({});
  const [renderNested, setRenderNested] = useState({});
  const [details, setDetails] = useState({
    "Name": "",
    "Role Applied For": "",
    "Date": ""
  });
  const [email, setEmail] = useState("");
  const [toast, setToast] = useState({ open: false, message: '', severity: '' });
  const [isChecked, setIsChecked] = useState(false);
  // State to store validation messages
  const [validationError, setValidationError] = useState("");

  // Validation function to check if an email is valid
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const reqId = urlParams.get("req_id");
  //   const applicantName = urlParams.get("name");

  const hostEnv = getConfig();

  useEffect(() => {
    if (applicantName) {
      setDetails((prev) => ({
        ...prev,
        "Name": applicantName,
        "Date": dayjs().format('DD/MM/YYYY'),
      }));
    }
  }, [applicantName]);

  const surveyType = "COI Candidate";
  const entity = hostEnv.entity;

  const endpoint = surveyEndpointMap[surveyType]?.[entity];

  useEffect(() => {
    axios
      .get(`${hostEnv.insightBaseUrl}/config/rating/hrms/${endpoint}`)
      .then((response) => {
        const data = response.data?.data;
        const questions = Object.values(data?.initialQuestions);
        const nestedQuestions = Object.values(data?.nestedQuestions);
        const optionQuestionsMap = data?.optionQuestionsMap;
        const sortedQuestions = questions.sort(
          (a, b) => a.sequence - b.sequence
        );
        const sortedNestedQuestions = nestedQuestions.sort(
          (a, b) => a.sequence - b.sequence
        );

        // Organize questions by section
        const sections = {};
        const initialValues = {};
        const optionIds = {};
        const optionWeights = {};
        // const nestedQuestions = data.nestedQuestions || {};
        // const optionQuestionsMap = data.optionQuestionsMap || {};

        sortedQuestions.forEach((question) => {
          const sectionTitle = question.metadata?.section?.title;
          if (!sections[sectionTitle]) {
            sections[sectionTitle] = [];
          }
          sections[sectionTitle].push(question);

          initialValues[question.id] = "";
          optionIds[question.id] = [];
          optionWeights[question.id] = {};
          question.options.forEach((option) => {
            optionIds[question.id].push(option.id);
            optionWeights[question.id][option.id] = option.weightage;
          });
        });

        sortedNestedQuestions.forEach((question) => {
          const sectionTitle = question.metadata?.section?.title;
          if (!sections[sectionTitle]) {
            sections[sectionTitle] = [];
          }
          sections[sectionTitle].push(question);

          initialValues[question.id] = "";
          optionIds[question.id] = [];
          optionWeights[question.id] = {};
          question.options.forEach((option) => {
            optionIds[question.id].push(option.id);
            optionWeights[question.id][option.id] = option.weightage;
          });
        });

        setFormQuestions(sortedQuestions);
        setSectionedQuestions(sections);
        setInitialValues(initialValues);
        setOptionIds(optionIds);
        setOptionWeights(optionWeights);
        setNestedQuestions(sortedNestedQuestions);
        setOptionQuestionsMap(optionQuestionsMap);
        setFeedbackIdentifier(data?.ssId);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching form questions:", error);
        setLoading(false);
      });
  }, [hostEnv.insightBaseUrl]);

  const sectionTitles = Object.keys(sectionedQuestions);

  const handleSubmit = async (values) => {
    let answerList = [];
    console.log("FINAL", values)

    console.log(values, "valuessss")

    // Loop through each key in values
    Object.keys(values).forEach((key) => {
      let questionId, optionId, comment;

      // Check if the key contains an underscore, indicating a questionId_optionId format
      if (key.includes('_')) {
        [questionId, optionId] = key.split('_');
        comment = values[key];
      } else {
        questionId = key;
        optionId = values[key];
        comment = "";
      }

      // Get the selected option weightage
      const selectedOptionWeightage = optionWeights[questionId][optionId];

      // Push the entry into the answerList
      answerList.push({
        "optionId": optionId,
        "weightage": "",
        "comment": comment,
        "metadata": {},
        "questionId": questionId
      });
    });

    console.log(answerList, "Answer List");

    const feedbackResponse = {
      "ssId": `${feedbackIdentifier}`,
      "identifierId": `${reqId}`,
      "identifierType": "CANDIDATE_COI_FEEDBACK",
      "feedbackGivenFor": "7976efa2-5d7b-481c-9e38-a1868c132892",
      "feedbackGivenForEntityType": `${hostEnv.entity}`,
      "answer": answerList,
      "feedbackGivenByMetadata": {
        "externalId": "sdfdsf-34234-sdfsdf-234234"
      },
      "feedbackGivenBy": `${email}_${applicantName}`,
      "feedbackGivenByEntityType": "CANDIDATE",
    };

    const headers = {
      "x-channel-name": "hrms_web",
      "token": 'Bearer token'
    };

    try {
      await axios.post(`${hostEnv.insightBaseUrl}/feedback`, feedbackResponse, {
        headers: headers
      });
      setSubmitted(true);
      setToast({ open: true, message: 'Feedback submitted successfully!', severity: 'success' });
      const userEmail = JSON.parse(localStorage.getItem("user-details"))?.Email;
      setCookie("coi-employee-feedback", userEmail, 90)
    } catch (error) {
      console.error('Error:', error);
      setToast({ open: true, message: 'Failed to submit feedback.', severity: 'error' });
    }
  };

  const handleNext = (values, validateForm) => {
    const sectionErrors = validateSection(currentSection.title, values);
    if (Object.keys(sectionErrors).length === 0) {
      if (currentSection.index < sectionTitles.length - 1) {
        setCurrentSection((prev) => ({
          index: prev.index + 1,
          title: sectionTitles[prev.index + 1],
        }));
      }
    } else {
      setOpen(true); // Show validation dialog if there are errors
    }
  };

  const validateSection = (sectionTitle, values) => {
    const sectionQuestions = sectionedQuestions[sectionTitle] || [];
    let errors = {};

    sectionQuestions.forEach((question) => {
      console.log(values, "HEDE")
      const EXCEPTED_SECTIONS = [
        "Candidate Details",
        "Conflict Of Interest"
      ]

      // Check if the main question is answered
      if (!(EXCEPTED_SECTIONS.includes(sectionTitle)) && (!values[question.id])) {
        errors[question.id] = "This field is required";
      }

      if (sectionTitle === 'Candidate Details') {
        // Check if any combined `question.id_option.id` value is empty
        question.options.forEach(option => {
          const fieldName = `${question.id}_${option.id}`;
          if (values[fieldName] === '') {
            errors[fieldName] = "This field is required";
          }
        });
      }

      // If the question has a nested question and it is rendered, validate it
      if (renderNested[question.id]?.render) {
        const nestedQuestionId = renderNested[question.id]?.data?.nestedQuestionId;
        const nestedQuestion = nestedQuestions.find(
          (q) => q.id === nestedQuestionId
        );

        if (nestedQuestion) {
          // Validate the nested question options
          nestedQuestion.options.forEach((option) => {
            const nestedFieldName = `${question.id}_${option.id}`;
            if (option.allowComment && !values[nestedFieldName]) {
              errors[nestedFieldName] = "This field is required";
            }
          });
        }
      }
    });

    return errors;
  };


  const handleChange = (questionId, value, values, setFieldValue) => {
    try {
      // Update the selected value
      setFieldValue(questionId, value);

      console.log('Initial Values:', values);

      let isPresent = false;
      let additionalData = null;

      // Get the value associated with the key from optionQuestionsMap
      const valueToCheck = optionQuestionsMap[value] ? optionQuestionsMap[value][0] : null;

      if (valueToCheck === null) {
        isPresent = false;
      } else {
        // Extract all 'id' values from objects in nestedQuestions
        const isNestedQuestion = nestedQuestions.map((item) => item.id);

        // Check if the valueToCheck is in idsInB
        isPresent = isNestedQuestion.includes(valueToCheck);
        additionalData = isPresent ? { nestedQuestionId: valueToCheck } : null;
      }

      // Remove any questionId_optionId keys from values if the option is not present in optionQuestionsMap
      Object.keys(values).forEach(key => {
        if (key.startsWith(`${questionId}_`)) {
          const optionId = key.split('_')[1];
          console.log(optionId, "optionss")
          if (!optionQuestionsMap[optionId]) {
            setFieldValue(key, undefined);  // Removes the key from Formik values
          }
        }
      });

      console.log(isPresent, "isPresent", additionalData, "additionalData");
      setRenderNested((prevState) => ({
        ...prevState,
        [questionId]: {
          render: isPresent,
          data: additionalData,
        },
      }));
    } catch (error) {
      console.error("Error in handleChange:", error.message);
    }
  };

  const handleBack = () => {
    if (currentSection.index > 0) {
      setCurrentSection((prev) => ({
        index: prev.index - 1,
        title: sectionTitles[prev.index - 1],
      }));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };


  const title = currentSection?.title;
  const sectionData = sectionedQuestions[title] && sectionedQuestions[title][0];
  const metadata = sectionData?.metadata?.section;

  const metaTitle = metadata?.title;
  const metaDesc = metadata?.description;
  const metaList = metadata?.details;
  const staticContent = metadata?.staticContent;

  const cookie = getCookie("coi-employee-feedback");

  return (
    <Fragment>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Box className="form-wrapper" p={{ xs: 2, sm: 4 }}
            sx={{
              width: { xs: '100%', sm: '80%', md: '70%' }, // Take full width on small screens, narrower on larger screens
              margin: 'auto', // Center the form on the page
              // Set a max width for larger screens
            }}>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <Form className="form-card-candidate">
                {cookie ? (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '16px' }}>
                    <Alert severity="success" sx={{ textAlign: 'left', alignItems: 'center', fontSize: '16px' }}>
                      The response has already been logged in our galactic database. No further action is required from your space station.
                    </Alert>
                  </div>
                ) : (
                  <Fragment>
                    <AlertSnackbar 
                      open={toast.open} 
                      message={toast.message} 
                      severity={toast.severity} 
                      onClose={() => setToast({ ...toast, open: false })} 
                    />
                    {/* {showFormImage && (
                  <Box
                    position="fixed"
                    bottom={0}
                    left={{ xs: '50%', sm: '200px' }}
                    transform={{ xs: 'translateX(-50%)', sm: 'none' }}
                  >
                    <img height={300} src={formImg} alt="Form Visual" />
                  </Box>
                )} */}
                    <Box p={{ xs: 2, sm: 4 }}>
                      <AppStepper
                        sectionedQuestions={sectionedQuestions}
                        currentIndex={currentSection.index}
                        title={currentSection.title}
                      />
                    </Box>
                    <Divider />
                    <Box p={{ xs: 2, sm: 6 }}>
                      <Typography
                        mb={2}
                        textAlign={'left'}
                        color={'#2B2B2B'}
                      >
                        <strong>{metaTitle === "Candidate Details" ? null : `${metaTitle}:`}</strong> {metaDesc}
                      </Typography>
                      <Box pl={2}>
                        <ul style={{ textAlign: 'left', marginBottom: '40px' }}>
                          {metaList?.map((item, index) => (
                            <li key={index} style={{ marginBottom: '8px', fontSize: '14px', color: '#2B2B2B', paddingLeft: '16px' }}>
                              {item?.key}: {item?.value}
                            </li>
                          ))}
                        </ul>
                      </Box>
                      <Box>
                        {staticContent?.map((item, index) => {
                          return (
                            <div style={{ textAlign: 'left', marginBottom: '16px' }}>
                              <p style={{ fontWeight: 'bold', color: '#2B2B2B' }}>{item?.heading}</p>
                              <p style={{ color: '#2B2B2B', marginTop: '8px', fontSize: '14px', lineHeight: '20px' }}>{item?.description}</p>
                            </div>
                          )
                        })}
                      </Box>
                      {submitted ? (
                        <Typography variant="h5" fontFamily="Roboto" fontWeight={500}>
                          Response Submitted
                        </Typography>
                      ) : (
                        <Stack spacing={3}>
                          {sectionedQuestions[currentSection.title]?.map((question) => (
                            <Box sx={{ color: '#2B2B2B' }} className="question-box" key={question.id}>
                              <Typography fontFamily="Roboto" fontWeight={500} mb={1}>
                                {
                                  (!question?.metadata?.showName && question?.metadata?.showName !== false) ? (
                                    <>
                                      {question.name} <span style={{ color: "red" }}>*</span>
                                    </>
                                  ) : null
                                }
                              </Typography>
                              <Field name={question.id}>
                                {({ field }) => (
                                  <>
                                    {(!question?.metadata?.showOptions && question?.metadata?.showOptions !== false && question.type === "SINGLE_SELECTION") ? (
                                      <RadioGroup
                                        {...field}
                                        name={question.id}
                                        value={values[question.id]}
                                        onChange={(event) =>
                                          handleChange(
                                            question.id,
                                            event.target.value,
                                            values,
                                            setFieldValue
                                          )
                                        }
                                      >
                                        {question.options.sort((a, b) => a.sequence - b.sequence).map((option) => (
                                          <FormControlLabel
                                            key={option.id}
                                            value={option.id}
                                            control={<Radio />}
                                            label={option.toolTip}
                                          />
                                        ))}
                                      </RadioGroup>
                                    ) : (!question?.metadata?.showOptions && question?.metadata?.showOptions !== false && question.type === "TEXT") ? (
                                      question.options.sort((a, b) => a.sequence - b.sequence).map((option) => {
                                        const tooltip = option?.toolTip;
                                        const userDetailValue = details[tooltip];
                                        const optionType = option?.metadata?.type;
                                        const showOptions = question?.metadata?.showOptions !== false
                                        const isDeclarationSection = title === "Declaration";


                                        const isPresent = Object.keys(values).find(
                                          (item) => item === `${question.id}_${option.id}`
                                        );

                                        if (!isPresent && !isDeclarationSection) {
                                          if (userDetailValue !== undefined) {
                                            setFieldValue(`${question.id}_${option.id}`, userDetailValue);
                                          }
                                        }

                                        return (
                                          <Box key={option.id} mb={2}>
                                            <Typography fontFamily="Roboto" fontWeight={500} mb={1}>
                                              {optionType !== "CHECKBOX" && option?.toolTip} <span style={{ color: "red" }}>{optionType !== "CHECKBOX" && '*'}</span>
                                            </Typography>
                                            {optionType === "CHECKBOX" ? (
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={values[`${question.id}_${option.id}`] || false}
                                                    onChange={(event) => {
                                                      setFieldValue(
                                                        `${question.id}_${option.id}`,
                                                        event.target.checked
                                                      )
                                                      setIsChecked(event.target.checked)
                                                    }
                                                    }
                                                  />
                                                }
                                                label={option.toolTip}
                                              />
                                            ) : (
                                              option.allowComment && (
                                                <TextField
                                                  {...field}
                                                  disabled={userDetailValue}
                                                  name={`${question.id}_${option.id}`}
                                                  value={userDetailValue || values[`${question.id}_${option.id}`]}
                                                  onChange={(event) => {
                                                    const { value } = event.target;

                                                    // Set the field value
                                                    setFieldValue(`${question.id}_${option.id}`, value);

                                                    // If tooltip is "Email", apply email validation and set email state
                                                    if (tooltip === "Email") {
                                                      setEmail(value)

                                                      if (!validateEmail(value)) {
                                                        setValidationError("Please enter a valid email address");
                                                      } else {
                                                        setValidationError(""); // Clear validation message if valid
                                                      }
                                                    }
                                                  }}
                                                  variant="outlined"
                                                  fullWidth
                                                  size="small"
                                                  placeholder={option.toolTip}
                                                  error={tooltip === 'Email' && Boolean(validationError)} // Set error state
                                                  helperText={tooltip === 'Email' && validationError} // Show validation message
                                                  sx={{ borderRadius: 6 }}
                                                />
                                              )
                                            )}
                                          </Box>
                                        );
                                      })
                                    ) : null}
                                  </>
                                )}
                              </Field>
                              {renderNested[question.id]?.render && (
                                <Box mt={4}>
                                  {nestedQuestions
                                    .filter(
                                      (nested) =>
                                        nested.id ===
                                        renderNested[question.id]?.data?.nestedQuestionId
                                    )
                                    .map((nested) => (
                                      <Grid container spacing={3} key={nested.id}>
                                        {nested.options.map((option) => (
                                          <Grid item xs={12} sm={6} key={option.id}>
                                            {option.allowComment && (
                                              <TextField
                                                name={`${question.id}_${option.id}`}
                                                value={values[`${question.id}_${option.id}`]}
                                                onChange={(event) =>
                                                  setFieldValue(
                                                    `${question.id}_${option.id}`,
                                                    event.target.value
                                                  )
                                                }
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                placeholder={option.toolTip}
                                                sx={{ borderRadius: 6 }}
                                              />
                                            )}
                                          </Grid>
                                        ))}
                                      </Grid>
                                    ))}
                                </Box>
                              )}
                            </Box>
                          ))}
                          {currentSection.title === "Declaration" && (
                            <Typography fontFamily="Roboto" mb={1} sx={{ "marginTop": "32px", "fontSize": "14px" }}>
                              <span style={{ color: "#AA4900" }}>You will not be able to edit or resubmit the declaration once submitted.</span>
                            </Typography>
                          )}
                          <Box textAlign="center" width="100%">
                            {currentSection.index !== 0 && (
                              <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={handleBack}
                                sx={{ marginRight: 1 }}
                              >
                                Back
                              </Button>
                            )}
                            {currentSection.title !== "Declaration" && (
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                disabled={Boolean(validationError)}
                                onClick={() => handleNext(values, validateSection)}
                                sx={{ marginRight: 1 }}
                              >
                                Next
                              </Button>
                            )}
                            {currentSection.title === "Declaration" && (
                              <Fragment>
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                  sx={{ marginLeft: 1 }}
                                  disabled={!isChecked}
                                >
                                  Submit
                                </Button>
                              </Fragment>
                            )}
                          </Box>
                        </Stack>
                      )}
                    </Box>
                  </Fragment>
                )}
              </Form>
            )}
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>{"Form Incomplete"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Ensure that all questions in the current mission log are completed before advancing to the next phase of your interstellar journey.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </Formik>
    </Fragment>
  );
};

export default COIFormCandidate;
