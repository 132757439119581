import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions,
  Divider,
  IconButton,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import axios from "axios";
import AppStepper from '../../AppStepper1';
import { toast } from 'react-toastify';
import RocketPage from '../RocketPage/RocketPage';
import "../MissionElevate.css";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import StepConnector from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '../../../images/check.svg'
import CheckBoxBaseIcon from '../../../images/checkbase.svg';
import ViewIcon from '../../../images/viewicon.svg';
import getConfig from '../../../helpers/getConfig';
import { X } from 'lucide-react';
import { red } from '@mui/material/colors';
import Lottie from "react-lottie";
import * as animation from '../../../images/rocket-animation.json';
const isMobile = window.innerWidth < 1024;
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  // [`&.${stepConnectorClasses.alternativeLabel}`]: {
  //   top: 10,
  //   left: 'calc(-50% + 16px)',
  //   right: 'calc(50% + 16px)',
  // },
  // [`&.${stepConnectorClasses.active}`]: {
  //   [`& .${stepConnectorClasses.line}`]: {
  //     borderColor: '#784af4',
  //   },
  // },
  // [`&.${stepConnectorClasses.completed}`]: {
  //   [`& .${stepConnectorClasses.line}`]: {
  //     borderColor: '#FFFFFF',
  //   },
  // },
  // [`& .${stepConnectorClasses.line}`]: {
  //   borderColor: '#eaeaf0',
  //   borderTopWidth: 3,
  //   borderRadius: 1,
  //   ...theme.applyStyles('dark', {
  //     borderColor: theme.palette.grey[800],
  //   }),
  // },
}));
// Custom Step Icon Component
function CustomStepIcon(props) {
  const { active, completed } = props;

  return (
    <div>
      {/* Conditionally style the icon based on active/completed state */}
      <SentimentSatisfiedAltIcon
        style={{
          color: completed ? 'yellow' : active ? 'gray' : 'gray',
        }}
      />
    </div>
  );
}


export default function JobListingMissionElevate({ setCurrentSection, sectionTitles }) {
  const theme = useTheme();
  const [rows, setRows] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [openJDModal, setOpenJDModal] = useState(false);
  const [selectedJobDescription, setSelectedJobDescription] = useState('');
  const [designation, setDesignation] = useState('');
  const [location, setLocation] = useState('');
  const [band, setBand] = useState('');
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [uploadingFileData, setUploadingFileData] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [appliedJobs, setAppliedJobs] = useState({});
  const [snappedBack, setSnappedBack] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const hostEnv = getConfig();

  const employeeCode = JSON.parse(localStorage.getItem("user-details"))?.["Employee ID"];

  const handleSubmitModal = () => {
    setSubmitModal(true)
  }
  const handleViewJD = (designation, location, band, jobDescription) => {
    setSelectedJobDescription(jobDescription);
    setBand(band);
    setLocation(location);
    setDesignation(designation);
    setOpenJDModal(true);
  };

  const handleCloseJDModal = () => {
    setSubmitModal(false);
    setOpenJDModal(false);
    setSelectedJobDescription('');
  };

  const handleAppliedJobs = (event, rowId) => {
    setAppliedJobs((prevAppliedJobs) => ({
      ...prevAppliedJobs,
      [rowId]: event.target.checked,
    }));
  };


  const handleSubmit = async () => {

    setLoading(true);
    const filteredJobData = Object.entries(appliedJobs)
      .filter(([key, value]) => value === true)
      .map(([key]) => key);

    console.log(filteredJobData, "filteredJobData")

    // Prepare payload for each file
    const payload = {
      employee_code: employeeCode,
      job_ids: filteredJobData,
    };


    try {
      // Perform upload for each file
      const response = await axios.post("https://mission-elevate-careers-prod-541034858590.asia-south1.run.app/job/apply", payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status >= 200 && response.status < 300) {
        toast.success(`Job Application submitted successfully!`);
        setIsSubmitted(true);
        setSnappedBack(false);
        setCurrentSection((prev) => ({
          index: prev.index !== 0 && prev.index + 1,
          title: sectionTitles[prev.index + 1],
        }));
      }
    } catch (error) {
      toast.error(`Error Submitting Job Application, please try again.`);
      setCurrentSection((prev) => ({
        index: prev.index + 1,
        title: sectionTitles[prev.index + 1],
      }));
      console.error(`Error uploading `, error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    setUploadingFileData(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://mission-elevate-careers-prod-541034858590.asia-south1.run.app/jobs?employee_code=${employeeCode}`);
        const sortedJobs = response?.data?.jobs.sort((a, b) => a.is_applied - b.is_applied) || [];
        setRows(sortedJobs || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSnapBack = () => {
    setSnappedBack(true);
  }

  const isAllJobApplied = rows?.every((item) => item?.is_applied === true);
  const isSmallScreen = window.innerWidth < 1024;

  const showRocketPage = (isSubmitted || isAllJobApplied) && snappedBack === false;

  return (
    <>
      {showRocketPage ? (
        <div style={{ height: '100%', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: "center", fontSize: '24px' }}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animation
            }}
            height={isMobile ? 300 : 400}
            width={isMobile ? 300 : 400}
          />
          <p style={{ fontSize: isMobile ? "16px" : "20px", padding: "0 16px" }}>
            Sealed the deal! You're good to go. 🔒
          </p>
        </div>


      ) : (
        <div style={{ padding: '' }}>
          <TableContainer sx={{ borderTopLeftRadius: '32px', borderTopRightRadius: '32px', border: '2px solid white', maxHeight: '300px', overflowY: 'auto', maxWidth: isSmallScreen ? `${window.innerWidth - 10}px` : "100%", maxHeight: "430px" }}>
            <Table aria-label="simple table">
              <TableHead sx={{}}>
                <TableRow sx={{ textAlign: 'center' }}>
                  <TableCell sx={{ fontSize: '14px', fontWeight: '800 !important', textAlign: 'center', borderRight: "2px solid white", backgroundColor: 'rgba(247, 147, 29, 1)', position: 'sticky', top: 0, zIndex: 1, color: 'white' }}>Rev It Up</TableCell>
                  <TableCell sx={{ fontSize: '14px', fontWeight: '800 !important', textAlign: 'center', borderRight: "2px solid white", backgroundColor: 'rgba(247, 147, 29, 1)', position: 'sticky', top: 0, zIndex: 1, color: 'white', }}>Autonaut Mission</TableCell>
                  <TableCell sx={{ fontSize: '14px', fontWeight: '800 !important', textAlign: 'center', borderRight: "2px solid white", backgroundColor: 'rgba(247, 147, 29, 1)', position: 'sticky', top: 0, zIndex: 1, color: 'white' }}>Torque Zone</TableCell>
                  <TableCell sx={{ fontSize: '14px', fontWeight: '800 !important', textAlign: 'center', borderRight: "2px solid white", backgroundColor: 'rgba(247, 147, 29, 1)', position: 'sticky', top: 0, zIndex: 1, color: 'white' }}>Band</TableCell>
                  <TableCell sx={{ fontSize: '14px', fontWeight: '800 !important', textAlign: 'center', backgroundColor: 'rgba(247, 147, 29, 1)', position: 'sticky', top: 0, zIndex: 1, color: 'white' }}>JD-Blueprint</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.length > 0 ? (
                  rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell sx={{ padding: '8px 16px !important', textAlign: 'center', borderRight: "2px solid white", backgroundColor: 'rgba(0, 0, 0, 1)', color: 'white' }}>
                        <Checkbox
                          icon={<img src={CheckBoxBaseIcon} alt="custom icon" />}
                          checkedIcon={<img src={CheckBoxIcon} alt="custom icon" />}
                          checked={appliedJobs[row.id] || row.is_applied || false}
                          disabled={row.is_applied || false}
                          onChange={(e) => handleAppliedJobs(e, row.id)}
                        />
                      </TableCell >
                      <TableCell sx={{ fontSize: '12px', padding: '8px  !important', textAlign: 'center', borderRight: "2px solid white", backgroundColor: 'rgba(0, 0, 0, 1)', color: 'white' }}>{row.designation}</TableCell>
                      <TableCell sx={{ fontSize: '12px', padding: '8px 16px !important', textAlign: 'center', borderRight: "2px solid white", backgroundColor: 'rgba(0, 0, 0, 1)', color: 'white' }}>{row.location}</TableCell>
                      <TableCell sx={{ fontSize: '12px', padding: '8px 16px !important', textAlign: 'center', borderRight: "2px solid white", backgroundColor: 'rgba(0, 0, 0, 1)', color: 'white' }}>{row.job_level}</TableCell>
                      <TableCell sx={{ fontSize: '12px', padding: '8px 16px !important', textAlign: 'center', backgroundColor: 'rgba(0, 0, 0, 1)', color: 'white' }}>
                        <Button onClick={() => handleViewJD(row.designation, row.location, row.job_level, row.job_description)} startIcon={<img src={ViewIcon} alt="custom icon" />} />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center" sx={{ backgroundColor: 'rgba(0, 0, 0, 1)', color: 'white' }}>
                      No data available
                    </TableCell>
                  </TableRow>
                )
                }
              </TableBody >
            </Table >
          </TableContainer >


          <Dialog className='job-description-modal' open={openJDModal} onClose={handleCloseJDModal} maxWidth="md" PaperProps={{
            sx: {
              position: "relative",
              backgroundColor: "#000000",
              border: "1px solid #F7931E",
              color: 'white',
              minHeight: "55%",
              maxHeight: isMobile ? "30%" : "60%",
              borderRadius: "16px",

            },
          }}>
            <IconButton
              type="button"
              variant="contained"
              color="primary"
              onClick={handleCloseJDModal}
              sx={{
                position: "absolute",
                top: "-44px",
                right: "0",
              }}
            >
              <X style={{ color: "red" }} />
            </IconButton>
            <DialogTitle className='job-description-title'></DialogTitle>
            <div style={{
              borderRadius: "10px",
              borderBottom: "1px solid #6EB4DE",
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              gap: '16px',
              padding: "0 8px 12px 16px"
            }}>
              <p style={{
                fontSize: "12px",
                backgroundColor: '#FFFFFF33',
                padding: '8px',
                border: '1px solid #F7931E',
                borderColor: "#F7931E",
                borderRadius: '0.4rem',
                fontSize: "12px"
              }}>{designation}</p>

              <p style={{
                fontSize: "12px",
                backgroundColor: '#FFFFFF33',
                padding: '8px',
                border: '1px solid #F7931E',
                borderColor: "#F7931E",
                borderRadius: '0.4rem',
                fontSize: "12px"
              }}>{location}</p>

              <p style={{
                fontSize: "12px",
                backgroundColor: '#FFFFFF33',
                padding: '8px',
                border: '1px solid #F7931E',
                borderColor: "#F7931E",
                borderRadius: '0.4rem',
                fontSize: "12px"

              }}>{band}</p>
            </div>

            <DialogContent sx={{ padding: "32px", fontSize: "12px" }}>
              <p dangerouslySetInnerHTML={{ __html: selectedJobDescription }} />
              {/* <p>{selectedJobDescription}</p> */}
            </DialogContent>
          </Dialog>

          {/* File Upload Confirmation Modal */}
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => handleSubmitModal()}
              // onClick={() => handleSubmit()}
              sx={{
                marginTop: 8,
                marginRight: 2,
                width: "140px",
                backgroundColor: "#F7931D",
              }}
            >
              Submit
            </Button>
          </div>

          <Dialog open={submitModal} onClose={handleCloseJDModal} PaperProps={{
            sx: {
              position: "relative",
              backgroundColor: "#000000",
              borderRadius: "20px",
              border: "1px solid #F7931D",
              color: 'white',
              minWidth: "40%",
              minHeight: "20%",
              maxHeight: "50%",


            },
          }}>
            <DialogContent sx={{ padding: "32px", fontSize: "12px" }}>
              <div style={{ fontFamily: "Inter", justifyContent: "center", justifyItems: 'center', textAlign: "center" }}>
                <h3 style={{ fontSize: "22px", }}>Is the deal locked in or still up in the air? 🔐✨</h3>

                <p style={{ fontSize: "16px", color: "gray" }} >Click Done-Deal to submit your response.</p>
              </div>
            </DialogContent>
            <div style={{
              display: 'flex', justifyContent: "center", gap: "10px", padding: "16px",
            }}>
              < Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => handleCloseJDModal()}
                sx={{
                  marginTop: 4,
                  width: "156px",
                  backgroundColor: "#FAFAFA4D",
                  borderRadius: "10px",
                  border: "1px"
                }}
              >
                Snap Back
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                disabled={loading}
                sx={{
                  "&.Mui-disabled": {
                    background: "#FBC07C",
                    color: "#FFFFFF"
                  },
                  marginTop: 4,
                  width: "156px",
                  backgroundColor: "#F7931D",
                  borderRadius: "10px",
                  border: "1px"

                }}
              >
                Done Deal
              </Button>
            </div>
          </Dialog >
        </div >
      )
      }
    </>
  );
}
