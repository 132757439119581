import { Stack } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import MissionElevate from "../Components/MissionElevateForm/MissionElevate";
import { Helmet } from "react-helmet";
import getConfig from "../helpers/getConfig";
import { ELIGIBLE_EMPLOYEES } from "../helpers/constants";
import AlertSnackbar from "../Components/core/AlertSnackbar/AlertSnackbar";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

export default function MissionElevateFormRoute() {
  const backgroundImg = 'mission-elevate-bg';
  const navigate = useNavigate();
  const hostEnv = getConfig()
  const [isAuthorizied, setIsAuthorized] = useState(null);

  const accessToken = localStorage.getItem("accessToken");
  const decodedToken = jwtDecode(accessToken);
  const email = decodedToken?.email;


  useEffect(() => {
    console.log(ELIGIBLE_EMPLOYEES.some(emp => emp.toLowerCase() === email.toLowerCase()))
    if (!ELIGIBLE_EMPLOYEES.some(emp => emp.toLowerCase() === email.toLowerCase())) {
      setIsAuthorized(false);
      setTimeout(() => {
        localStorage.removeItem("accessToken");
        localStorage.setItem("pathname", "/mission-elevate");
        navigate('/login');
      }, 2000);
    } else {
      setIsAuthorized(true);
    }
  }, [email, isAuthorizied, navigate]);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mission Elevate</title>
        <link rel="icon" href={hostEnv.favicon} />
      </Helmet>
      <Stack className={backgroundImg} id='base-container' sx={{ height: "100vh", position: 'relative' }}>
        {isAuthorizied ? <MissionElevate /> : <AlertSnackbar open={true} message="You are not authorized to access this page." />}
      </Stack>
    </Fragment>
  )
}