import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children }) => {
  const token = localStorage.getItem('accessToken');

  if (token) {
    // If token is found, redirect to the home page
    return <Navigate to="/" replace />;
  }

  // If no token, render the children (login page)
  return children;
};

export default PublicRoute;