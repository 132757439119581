import * as React from 'react';
import "../MissionElevate.css";
import Lottie from "react-lottie";
import * as animation from '../../../images/rocket-animation.json';
import { useState } from 'react';
import {
  Button
} from "@mui/material";

export default function RocketPage({ isAllJobApplied, handleSnapBack }) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleSubmit = async () => {

    setIsSubmitted(true)
  }

  const isMobile = window.innerWidth < 1024;

  return (
    <>
      {isSubmitted || isAllJobApplied ? (
        <div style={{ height: '100%', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: "center", fontSize: '24px' }}>
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: animation
            }}
            height={isMobile ? 300 : 400}
            width={isMobile ? 300 : 400}
          />
          <p style={{ fontSize: isMobile ? "16px" : "20px", padding: "0 16px" }}>
            Sealed the deal! You're good to go. 🔒
          </p>
        </div>
      ) : (
        <div style={{
          marginTop: 0
        }}>

          <div style={{ fontSize: isMobile ? "16px" : "20px", padding: "0 16px" }} >
            Is the deal locked in or still up in the air? Let us know! 🔐✨
          </div>
          <div style={{ marginTop: "16px" }}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSnapBack}
              sx={{
                marginTop: "16px",
                marginRight: 2,
                width: "140px",
                backgroundColor: "RGBA(250,250,250,.30)"
              }}
            >
              Snap Back
            </Button>
            <Button

              type="button"
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              sx={{
                marginTop: "16px",
                marginRight: 2,
                width: "140px",
                background: '#0171BC'
              }}
            >
              Done deal
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
