import { Alert } from "@mui/material";

export default function AlreadySubmitted() {

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '16px' }}>
      <Alert severity="success" sx={{ textAlign: 'left', alignItems: 'center', fontSize: '16px' }}>
        The response has already been logged in our galactic database. No further action is required from your space station.
      </Alert>
    </div>
  )
}