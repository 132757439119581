// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../images/Back.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginFormMobile {
  padding: 16px;
  height: 100vh;
  background: #000000;
  color: white;
}

.loginFormMobileBanner {
  height: 380px;
  /* background: linear-gradient(to bottom left, #0071BC, #004E7F); */
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  border: 1px solid grey;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginMobileTitle {
  font-size: 24px;
  line-height: 36px;
}`, "",{"version":3,"sources":["webpack://./src/Components/login/LoginFormMobile/loginFormMobile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mEAAmE;EACnE,yDAAiD;EACjD,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".loginFormMobile {\n  padding: 16px;\n  height: 100vh;\n  background: #000000;\n  color: white;\n}\n\n.loginFormMobileBanner {\n  height: 380px;\n  /* background: linear-gradient(to bottom left, #0071BC, #004E7F); */\n  background-image: url('../../../images/Back.png');\n  border: 1px solid grey;\n  border-radius: 30px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.loginMobileTitle {\n  font-size: 24px;\n  line-height: 36px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
